import React, { useState, useEffect } from "react";
import { Typography, Button, Switch } from "@mui/material";
import CategoryReportForm from "../../components/CategoryReportForm";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../theme";
import Modal from "react-modal";
import { toast } from "react-toastify";
import API_URLS from "../../summaryapi/apiUrls";
import './CategoryForm.css';

Modal.setAppElement("#root");

const CategoryForm = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [modalOpen, setModalOpen] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [categoryImage, setCategoryImage] = useState(null);
  const [isActive, setIsActive] = useState(true);
  const [categories, setCategories] = useState([]);
  const [editingCategory, setEditingCategory] = useState(null);
  const [categoryId, setCategoryId] = useState(null);

  // Fetch categories function moved out of useEffect
  const fetchCategories = async () => {
    try {
      const response = await fetch(API_URLS.getAllCategories.url);
      const data = await response.json();
      if (data && Array.isArray(data.data)) {
        // Sort categories based on created_date in descending order (latest first)
        const sortedCategories = data.data.sort((a, b) => {
          const dateA = new Date(a.created_date); // Convert to Date object
          const dateB = new Date(b.created_date); // Convert to Date object
          return dateB - dateA; // Sort in descending order (latest first)
        });
        setCategories(sortedCategories);
      } else {
        console.error("Invalid data format", data);
      }
    } catch (err) {
      console.error("Error fetching categories:", err);
      toast.error("Failed to load categories.");
    }
  };

  
  useEffect(() => {
    if (modalOpen) {
      document.body.classList.add("overflow-hidden"); // Lock body scroll
    } else {
      document.body.classList.remove("overflow-hidden"); // Unlock body scroll
    }

    // Clean up on component unmount or modal close
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [modalOpen]); // Runs every time modalOpen changes



  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCategoryImage(file);
    }
  };
  // Fetch categories when component mounts
  useEffect(() => {
    fetchCategories(); // Fetch categories on mount
  }, []); // Empty dependency array to run once on component mount

  const handleAddOrUpdateCategory = async () => {
    if (!categoryName && !categoryImage && editingCategory) {
      toast.info("No changes made.");
      return;
    }

    const formData = new FormData();
    formData.append("category_name", categoryName);
    formData.append("is_active", isActive ? "1" : "0");
    if (categoryImage) {
      formData.append("image", categoryImage);
    }

    if (categoryId) {
      formData.append("category_id", categoryId);
    }

    try {
      const response = await fetch(
        editingCategory ? API_URLS.updateServiceCategory.url : API_URLS.createcategory.url,
        {
          method: editingCategory ? API_URLS.updateServiceCategory.method : API_URLS.createcategory.method,
          body: formData,
        }
      );

      const result = await response.json();
      if (result.status) {
        await fetchCategories(); // Reload categories after adding or updating
        toast.success(editingCategory ? "Category updated successfully!" : "Category added successfully!");
      } else {
        // Handle the specific error for duplicate category name
        if (result.message === "Category Name already exists.") {
          toast.error("Category Name already exists. Please choose a different name.");
        } else {
          toast.error("Failed to save category.");
        }
      }
    } catch (err) {
      toast.error("Error saving category: " + err.message);
    }
  

    setModalOpen(false);
    resetForm();
  };



  const openEditModal = (category) => {
    if (category && category.category_id) {
      setCategoryName(category.category_name);
      setIsActive(category.is_active === "1");
      setEditingCategory(category);
      setCategoryId(category.category_id);
      setCategoryImage(null);
      setModalOpen(true);
    } else {
      console.error("Invalid category object passed to openEditModal.");
    }
  };

  const resetForm = () => {
    setCategoryName("");
    setCategoryImage(null);
    setIsActive(true);
    setEditingCategory(null);
    setCategoryId(null);
  };

  return (
    <div className="w-90">
      <div className="font-poppins font-bold text-center text-[30px]">
        Categories
      </div>

      <div className="flex justify-end mb-[16px] w-[95%]">
        <Button
          variant="contained"
          onClick={() => {
            resetForm();
            setModalOpen(true);
          }}
          color="success"
          size="large"
          className="w-40 text-white font-semibold pr-4 font-poppins"
        >
          Add Category
        </Button>
      </div>

      <CategoryReportForm
        categories={categories}
        onEditCategory={openEditModal}
      />

      <Modal
 isOpen={modalOpen}
 onRequestClose={() => setModalOpen(false)}
 contentLabel={editingCategory ? "Edit Category" : "Add New Category"}
 className="modal-content"
 overlayClassName="modal-overlay"
      >
        <div>
          <Typography
            variant="h4"
            component="h2"
            textAlign="center"
            className="font-bold text-black text-[20px] font-poppins"
          >
            {editingCategory ? "Edit Category" : "Add New Category"}
          </Typography>

          <div className="space-y-4">
            <div className="inputGroup">
              <label
                htmlFor="category-name"
                className="block text-sm font-medium text-gray-700"
              >
                Category Name
              </label>
              <input
                type="text"
                id="category-name"
                placeholder="Enter category name"
                style={{
                  borderColor: colors.primary[300],
                  backgroundColor: colors.primary[400],
                  color: colors.primary[100],
                }}
                value={categoryName}
                onChange={(e) => setCategoryName(e.target.value)}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>

            <div className="inputGroup fileInputContainer">
              <label className="block text-sm font-medium text-gray-700">
                Category Image
              </label>
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                className="mt-1 block w-full text-sm text-gray-500 file:mr-2 file:py-2 file:px-4 file:border file:border-transparent file:rounded-full file:text-sm file:bg-blue-500 file:text-white hover:file:bg-blue-600"
              />
              {categoryImage && (
                <img
                  src={URL.createObjectURL(categoryImage)}
                  alt="Category Preview"
                  className="mt-2 rounded-lg object-cover w-24 h-24"
                />
              )}
              {editingCategory && !categoryImage && editingCategory.category_image && (
                <img
                  src={`${API_URLS.getCategoryImages.url}${editingCategory.category_image}`}
                  alt="Category Image"
                  className="mt-2 rounded-lg object-cover w-24 h-24"
                />
              )}
            </div>

            <div className="flex items-center">
              <Typography className="block font-bold" style={{ color: colors.primary[200] }}>Active</Typography>
              <Switch
                checked={isActive}
                onChange={(e) => setIsActive(e.target.checked)}
                style={{
                  color: colors.greenAccent[500],
                }}
              />
            </div>

            <div className="flex justify-between">
              <Button
                variant="contained"
                onClick={() => {
                  setModalOpen(false);
                  resetForm();
                }}
                className="bg-gray-500 text-black py-2 px-4 rounded-md hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500"
              >
                Cancel
              </Button>

              <Button
                variant="contained"
                onClick={handleAddOrUpdateCategory}
                className="bg-green-500 text-white py-2 px-4 rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500"
              >
                {editingCategory ? "Update" : "Add"} Category
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CategoryForm;
