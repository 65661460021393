import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { useTheme } from '@mui/material/styles';
import { Button, Switch } from '@mui/material';
import { tokens } from '../theme'; // Ensure tokens are imported correctly
import API_URLS from "../summaryapi/apiUrls";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

Modal.setAppElement('#root');

const ServiceModal = ({
  isModalOpen,
  closeModal,
  handleChange,
  handleImageChange,
  handleSubmit,
  newService,
  editingService,
  fetchServices, // Function to refresh service list after update or creation
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode); // Get the colors based on current theme mode

  const imagePreview1 = newService.service_image1 
    ? URL.createObjectURL(newService.service_image1)
    : editingService?.service_image1 
      ? `${API_URLS.getServiceImages.url}${editingService.service_image1}` 
      : null;

  const imagePreview2 = newService.service_image2 
    ? URL.createObjectURL(newService.service_image2)
    : editingService?.service_image2 
      ? `${API_URLS.getServiceImages.url}${editingService.service_image2}` 
      : null;

  const modalHeightClass = editingService ? 'max-h-[calc(100vh-20px)]' : 'max-h-[calc(100vh-20px)]';  

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      contentLabel="Add or Edit Service"
      className={`bg-white p-8 rounded shadow-lg w-[60vw] sm:w-[40vw] md:w-[30vw] mx-auto ${modalHeightClass} overflow-y-auto`}
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
    >
      <h2 className="text-2xl mb-4 text-center font-bold" style={{ color: colors.primary[100] }}>
        {editingService ? "Edit Service" : "Add New Service"}
      </h2>
      <form onSubmit={handleSubmit}>

        {/* Vehicle Type Radio Buttons */}
        <div className="mb-4">
          <FormControl component="fieldset" fullWidth>
            <FormLabel
              component="legend"
              style={{ color: colors.primary[200], fontWeight: 700 }}
              className="block font-bold"
            >
              Vehicle Type
            </FormLabel>
            <RadioGroup
              name="vehicle_type"
              value={newService.vehicle_type || "2-wheeler"} // Default to "2-wheeler" if no value is set
              onChange={handleChange}
              row
            >
              <FormControlLabel
                value="2-wheeler"
                control={<Radio />}
                label="2-Wheeler"
                style={{ color: colors.primary[100] }}
              />
              <FormControlLabel
                value="4-wheeler"
                control={<Radio />}
                label="4-Wheeler"
                style={{ color: colors.primary[100] }}
              />
            </RadioGroup>
          </FormControl>
        </div>

        {/* Service Name and Subtitle in One Row */}
        <div className="mb-4 flex space-x-4">
          <div className="w-1/2">
            <label className="block mb-2 font-bold" style={{ color: colors.primary[200] }}>Service Name</label>
            <input
              type="text"
              name="service_name"
              placeholder="Enter Service Name"
              value={newService.service_name}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded"
              style={{
                borderColor: colors.primary[300],
                backgroundColor: colors.primary[400],
                color: colors.primary[100],
              }}
              required
            />
          </div>

          <div className="w-1/2">
            <label className="block mb-2 font-bold" style={{ color: colors.primary[200] }}>Subtitle</label>
            <input
              type="text"
              placeholder="Enter Service Subtitle"
              name="service_subtitle"
              value={newService.service_subtitle}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded"
              style={{
                borderColor: colors.primary[300],
                backgroundColor: colors.primary[400],
                color: colors.primary[100],
              }}
              required
            />
          </div>
        </div>

        {/* Service Description */}
        <div className="mb-4">
          <label className="block mb-2 font-bold" style={{ color: colors.primary[200] }}>Description</label>
          <textarea
            name="service_description"
            placeholder="Enter Service Description"
            value={newService.service_description}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded"
            style={{
              borderColor: colors.primary[300],
              backgroundColor: colors.primary[400],
              color: colors.primary[100],
            }}
            required
          />
        </div>

        {/* Service Price */}
        <div className="mb-4">
          <label className="block mb-2 font-bold" style={{ color: colors.primary[200] }}>Price</label>
          <input
            type="number"
            name="service_price"
            placeholder="Enter Service Price"
            value={newService.service_price}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded"
            style={{
              borderColor: colors.primary[300],
              backgroundColor: colors.primary[400],
              color: colors.primary[100],
            }}
            required
          />
        </div>

        {/* Service Image Fields in One Row */}
        <div className="mb-4 flex space-x-4">
          <div className="w-1/2">
            <label className="block mb-2 font-bold" style={{ color: colors.primary[200] }}>Service Image 1</label>
            <input
              type="file"
              name="service_image1"
              onChange={(e) => handleImageChange(e, 'service_image1')}
              className="w-full px-3 py-2 border rounded"
              style={{
                borderColor: colors.primary[300],
                backgroundColor: colors.primary[400],
                color: colors.primary[100],
              }}
            />
            {imagePreview1 && (
              <div className="mt-2">
                <img
                  src={imagePreview1}
                  alt="Service Preview"
                  className="w-24 h-24 object-cover rounded"
                  style={{ border: `1px solid ${colors.primary[300]}`, padding: '4px' }}
                />
              </div>
            )}
          </div>

          <div className="w-1/2">
            <label className="block mb-2 font-bold" style={{ color: colors.primary[200] }}>Service Image 2</label>
            <input
              type="file"
              name="service_image2"
              onChange={(e) => handleImageChange(e, 'service_image2')}
              className="w-full px-3 py-2 border rounded"
              style={{
                borderColor: colors.primary[300],
                backgroundColor: colors.primary[400],
                color: colors.primary[100],
              }}
            />
            {imagePreview2 && (
              <div className="mt-2">
                <img
                  src={imagePreview2}
                  alt="Service Preview"
                  className="w-24 h-24 object-cover rounded"
                  style={{ border: `1px solid ${colors.primary[300]}`, padding: '4px' }}
                />
              </div>
            )}
          </div>
        </div>

        {/* Active Status */}
        <div className="mb-4 flex items-center">
          <label className="block font-bold" style={{ color: colors.primary[100] }}>
            Active
          </label>
          <Switch
            name="is_active"
            checked={newService.is_active == "1" || newService.is_active === true} // Ensure it's a boolean
            onChange={handleChange}
            style={{
              color: colors.greenAccent[500],
            }}
          />
        </div>

        {/* Submit & Cancel Buttons */}
        <div className="mt-4 flex justify-between">
          <Button onClick={closeModal} variant="outlined" color="secondary" style={{ width: '48%' }}>Cancel</Button>
          <Button type="submit" variant="contained" color="primary" style={{ width: '48%' }}>
            {editingService ? "Save Changes" : "Add Service"}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default ServiceModal;
