import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import { tokens } from "../theme";  // Assuming tokens are for color styling
import API_URLS from "../summaryapi/apiUrls";  // Make sure API URLs are correctly configured
import { Card, CardContent, CardMedia } from '@mui/material';
const BookingDetails = () => {
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const fetchBookings = async () => {
    try {
      const response = await axios.get(API_URLS.getAllBookingData.url);
      
      if (response.data.status === false) {
        setError("No bookings found.");
      } else {
        // Sort the bookings by created_date in descending order
        const sortedBookings = response.data.data.sort((a, b) => {
          const dateA = new Date(a.created_date); // Convert created_date to Date object
          const dateB = new Date(b.created_date); // Convert created_date to Date object
          return dateB - dateA; // Sort descending: more recent dates first
        });
  
        setBookings(sortedBookings);
      }
      
      setLoading(false);
    } catch (err) {
      setError("Failed to fetch booking details.");
      setLoading(false);
    }
  };
  

  useEffect(() => {
    fetchBookings();
  }, []);

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedBooking(null);
  };

  const handleViewClick = (booking) => {
    setSelectedBooking(booking);
    setOpenModal(true);
  };

  // Define columns for the DataGrid
// Define columns for the DataGrid
const columns = [
  {
    field: "booking_code",
    headerName: "Booking Code",
    flex: 0.2,
    headerClassName: "header-cell",
  },
  {
    field: "package_name",
    headerName: "Package/Service Name",
    flex: 0.3,
    renderCell: (params) => {
      const booking = params.row;
      
      return (
        <div style={{
          textAlign: 'center', // Center the content horizontally
          display: 'flex', 
          justifyContent: 'center', // Center the content horizontally
          alignItems: 'center', // Center the content vertically
          minHeight: '80px',  // Ensure a minimum height for all cells to maintain alignment
          padding: '10px',  // Uniform padding for all cells
          height: '100%', // Ensures full height of the cell is used
          boxSizing: 'border-box', // Ensures padding doesn't affect total height/width
        }}>
          {booking.package_id > 0 ? (
            // Display Package Name
            <strong>
              <span
                style={{
                  backgroundColor: colors.greenAccent[700],
                  color: colors.primary[100],
                  padding: "2px 6px",
                  borderRadius: "4px",
                  marginRight: "5px",
                }}
              >
                Package
              </span>
              {booking.package_name}
            </strong>
          ) : (
            // Display Service Name
            <div>
              {booking.itemsData?.map((item, idx) => (
                <div key={idx} style={{ marginBottom: '5px' }}>
                  <strong>
                    <span
                      style={{
                        backgroundColor: colors.redAccent[700],
                        color: colors.primary[100],
                        padding: "2px 6px",
                        borderRadius: "4px",
                        marginRight: "5px",
                      }}
                    >
                      Service
                    </span>
                    {item.service_name || "N/A"} {/* Show service_name if available */}
                  </strong>
                </div>
              ))}
            </div>
          )}
        </div>
      );
    },
    headerClassName: "header-cell",
    headerAlign: 'center', // Center header content
    align: 'center', // Center body cell content
  }
  ,
  {
    field: "service_id",
    headerName: "Service ID",
    flex: 0.2,
    hide: true,
    renderCell: (params) => {
      const booking = params.row;
  
      // If package_id exists, do not show service_id
      if (booking.package_id > 0) {
        return "N/A";
      } else {
        // If package_id is 0, map through itemsData to show service_id
        return booking.itemsData && booking.itemsData.length > 0 
          ? booking.itemsData.map((item, idx) => (
              <div key={idx}>{item.service_id ? item.service_id : "N/A"}</div>  // Show service_id or "N/A" if not available
            ))
          : "N/A";  // If no itemsData, show "N/A"
      }
    },
    headerClassName: "header-cell",
  },
  
  {
    field: "booking_price",
    headerName: "Booking Price",
    flex: 0.2,
    renderCell: (params) => `₹${params.row.booking_price}`,
    headerClassName: "header-cell",
  },
  {
    field: "booking_mode",
    headerName: "Booking Mode",
    flex: 0.2,
    renderCell: (params) => `${params.row.booking_mode}`,
    headerClassName: "header-cell",
  },
  {
    field: "total_payable_price",
    headerName: "Total Payable Price",
    flex: 0.3,
    renderCell: (params) => `₹${params.row.total_payable_price}`,
    headerClassName: "header-cell",
  },
  {
    field: "view_services",
    headerName: "View Services",
    renderCell: (params) => (
      <Button
        onClick={() => handleViewClick(params.row)}
        sx={{
          backgroundColor: colors.greenAccent[500],
          color: "#fff",
          fontWeight: "bold",
          "&:hover": {
            backgroundColor: colors.greenAccent[700],
          },
        }}
      >
        View Details
      </Button>
    ),
    flex: 0.3,
    headerClassName: "header-cell",
  },
  {
    field: "assign",
    headerName: "Assign",
    renderCell: (params) => (
      <Button
        onClick={() => handleAssignClick(params.row)}
        sx={{
          backgroundColor: colors.blueAccent[500],
          color: "#fff",
          fontWeight: "bold",
          "&:hover": {
            backgroundColor: colors.blueAccent[700],
          },
        }}
      >
        Assign
      </Button>
    ),
    flex: 0.2,
    headerClassName: "header-cell",
  },
];



const handleAssignClick = (booking) => {
  console.log("Booking Data: ", booking);

  // Choose the ID you want to use, prefer package_id over service_id if available
  const selectedBookingId = booking.id;
  
  if (selectedBookingId) {
    // Construct the URL with the selected ID
    const url = `/assign-booking?customer_booking_id=${selectedBookingId}`;
    
    // Open the /assign-booking page in a new tab
    const newWindow = window.open(url, '_blank');
    
    // Wait for the new window to load completely before sending the data
    newWindow.addEventListener('load', () => {
      const messageData = {
        booking: booking,  // Send the full booking data
      };

      // Only include itemsData if available
      if (Array.isArray(booking.itemsData) && booking.itemsData.length > 0) {
        messageData.itemsData = booking.itemsData;  // Include itemsData if available
      }

      // Post message to new window
      newWindow.postMessage(messageData, window.location.origin);
    });
  } else {
    console.error("Invalid booking or missing booking ID.");
  }
};






  // Map the booking data to rows for the DataGrid
  const rows = bookings.map((booking) => ({
    id: booking.customer_booking_id,
    booking_code: booking.booking_code,
    package_name: booking.package_name || booking.service_name,
    full_name: booking.full_name,
    mobile_number: booking.mobile_number,
    service_booked_date: booking.service_booked_date,
    package_price: booking.package_price,
    booking_mode: booking.booking_mode,
    booking_price: booking.booking_price,
    total_payable_price: booking.total_payable_price,
    itemsData: booking.itemsData,
    package_id: booking.package_id,  // Ensure package_id is included in the row data
    service_id: booking.service_id,  // Ensure service_id is included in the row data
  }));
  
  return (
    <Box m="30px 0 0 0" margin={10}>
<h3 className="text-center mb-4">Booking Report</h3>

      {loading ? (
        <div>Loading...</div>
      ) : error ? (
        <div>{error}</div>
      ) : (
        <div style={{ height: 650, width: "100%"}}>
          
<DataGrid
  rows={rows}
  columns={columns.map((col) => ({
    ...col,
    renderCell: (params) => (
      <div style={{ textAlign: 'center', width: '100%' }}>
        {col.renderCell ? col.renderCell(params) : params.value}
      </div>
    ),
    headerClassName: "header-cell",
    headerAlign: 'center',  // Ensures headers are centered
    align: 'center',        // Ensures cell content is centered
  }))}
  components={{ Toolbar: GridToolbar }}
  disableSelectionOnClick
  sx={{
    "& .MuiDataGrid-root": {
      border: "none",
    },
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: colors.blueAccent[700],
      borderBottom: `2px solid ${colors.primary[200]}`,
      fontFamily: '"Poppins", sans-serif',
      fontWeight: "bold",
      fontSize: "16px",
      color: colors.primary[100],
      textAlign: "center",  // Center content for header cells
    },
    "& .MuiDataGrid-cell": {
      textAlign: "center",  // Center content for body cells
    },
    "& .MuiDataGrid-virtualScroller": {
      backgroundColor: colors.primary[400],
    },
    "& .MuiDataGrid-footerContainer": {
      backgroundColor: colors.blueAccent[700],
      borderTop: `2px solid ${colors.primary[200]}`,
    },
    "& .MuiDataGrid-columnSeparator": {
      display: "none",  // Optional: hides the column separator for a cleaner look
    },
    "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
      width: "auto",  // Ensure that all columns have automatic width based on content
    },
    "& .MuiDataGrid-columnHeader": {
      minWidth: 120,  // Minimum width for headers to make sure they all align
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      textAlign: "center",  // Center text inside header cells
    },
  }}
/>




        </div>
      )}
      <Dialog open={openModal} onClose={handleCloseModal}  sx={{ "& .MuiDialog-paper": { width: "90%" } }}>
        <DialogTitle className="text-xl font-semibold text-center" sx={{ color: colors.primary[100] }}>
          Service Details
        </DialogTitle>
        <DialogContent className="p-3 space-y-2">
  <div className="grid grid-cols-2 gap-6">
    {selectedBooking?.itemsData?.map((item, idx) => (
      <Card key={idx} sx={{ maxWidth: 220, height: 200, borderRadius: "8px", boxShadow: 3, overflowY: 'auto' }}>
        
        {/* Scrollable Image Section */}
        {item.service_image2 && (
          <div style={{ height: '100px', overflowY: 'auto' }}>
            <CardMedia
              component="img"
              image={`${API_URLS.getServiceImages.url}${item.service_image2}`}
              alt={item.service_name}
              sx={{
                objectFit: 'cover', 
                borderRadius: "8px 8px 0 0", 
                height: '100%',
                width: '100%', 
              }}
            />
          </div>
        )}

        {/* Scrollable Content Section */}
        <CardContent sx={{ textAlign: "center", padding: "12px", height: 'calc(100% - 120px)', overflowY: 'auto' }}>
          <Typography variant="h6" component="div" sx={{ fontWeight: "bold", color: colors.primary[100] }}>
            {item.service_name}
          </Typography>
          <Typography variant="body1" sx={{ color: colors.primary[200], margin: "6px 0" }}>
            ₹{item.service_price}
          </Typography>
          <Typography variant="body2" sx={{ color: colors.primary[100], fontSize: "0.875rem" }}>
            {item.service_description}
          </Typography>
        </CardContent>
      </Card>
    ))}
  </div>
</DialogContent>
        <DialogActions className="p-4">
          <Button
            onClick={handleCloseModal}
            color="primary"
            className="bg-blue-500 text-white font-semibold px-6 py-2 rounded-lg hover:bg-red-600"
            sx={{
              backgroundColor: colors.primary[400],
              color: colors.primary[100],
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default BookingDetails;
