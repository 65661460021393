import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { useTheme } from '@mui/material/styles';
import { tokens } from "../theme";
import API_URLS from '../summaryapi/apiUrls'; // Import the API URLs
import axios from 'axios'; // Ensure axios is installed for making HTTP requests
import { toast } from "react-toastify";

Modal.setAppElement("#root"); // Set the app element for accessibility

const PackageServiceModal = ({ open, onClose, packageId, packageName, packagePrice }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [allServices, setAllServices] = useState([]); // List of all available services
  const [selectedServices, setSelectedServices] = useState([]); // List of selected services
  const [loading, setLoading] = useState(true); // Start loading by default
  const [error, setError] = useState(null);

  // Fetch all available services (for the left side list of checkboxes)
  const fetchAllServices = () => {
    if (!packageId || packageId === 0) return;
    setLoading(true);
    axios.put(
      API_URLS.getPackageServices.url,
      JSON.stringify({ package_id: packageId }),
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
      .then(response => {
        const fetchedServices = response.data.data || [];
        setAllServices(fetchedServices);
      })
      .catch(error => {
        setError('Failed to fetch services');
        console.error('Error fetching services:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchSelectedServices = () => {
    if (!packageId || packageId === 0) return;

    setLoading(true);
    axios.post(
      API_URLS.getAllPackageServices.url, // The URL
      JSON.stringify({ package_id: packageId }), // Send package_id in the request body
      {
        headers: {
          'Content-Type': 'application/json', // Ensure the server expects JSON
        },
      }
    )
      .then(response => {
        const fetchedSelectedServices = response.data.data || [];
        setSelectedServices(fetchedSelectedServices);
      })
      .catch(error => {
        console.error('Error fetching selected services:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // When modal opens, fetch both all and selected services
  useEffect(() => {
    if (open && packageId && packageId !== 0) {
      fetchAllServices();
      fetchSelectedServices();
    }

    return () => {
      setSelectedServices([]); // Reset selected services when modal is closed
    };
  }, [open, packageId]); // Fetch services when modal is opened or packageId changes

  // Handle checkbox change (to select or unselect services)
  const handleCheckboxChange = (service_id) => {
    setSelectedServices((prevSelected) => {
      const isSelected = prevSelected.some(service => service.service_id === service_id);
      if (isSelected) {
        // Remove the service if already selected
        return prevSelected.filter(service => service.service_id !== service_id);
      } else {
        // Add the service if not selected
        const service = allServices.find(service => service.service_id === service_id);
        return [...prevSelected, service];
      }
    });
  };

  const handleSaveOrUpdate = () => {
    // Prepare the selected service data
    const updatedServices = selectedServices.map(service => service.service_id);
  
    // Prepare the request payload
    const requestData = {
      package_id: packageId,
      service_ids: updatedServices.length > 0 ? updatedServices : []  // Ensure service_ids is always included, even if empty
    };
  
    console.log("Request Data:", requestData);
  
    let apiUrl = API_URLS.updatePackagesServices.url; // Use update API for both creating and updating services
  
    axios.post(
      apiUrl,
      JSON.stringify(requestData),
      {
        headers: {
          'Content-Type': 'application/json', // Ensure the server expects JSON
        },
      }
    )
      .then(response => {
        if (response.data && response.data.length > 0) {
          // If the response contains multiple service update statuses
          response.data.forEach(item => {
            if (item.status === true) {
              // If the service was activated successfully
              toast.success(item.message || "Service activated successfully."); // Show the success message
            } else {
              toast.error(item.message || "Failed to update service."); // Handle failure for individual services
            }
          });
        } else if (response.data && response.data.status === true) {
          // If no individual services are passed, but the package was successfully updated
          const message = updatedServices.length === 0
            ? response.data.message || "All Services removed from given package." // Custom message if no services are selected
            : response.data.message || "Package services updated successfully"; // Default success message if services are selected
  
          toast.success(message); // Show success message in toast
        }
        
        onClose(); // Close the modal after success
      })
      .catch(error => {
        console.error("Error saving or updating package services:", error);
        alert("An error occurred while saving or updating the services.");
      });
  };
  
  
  
  if (!packageId || packageId === 0 || loading) {
    return null; // Don't render the modal if packageId is 0 or not available or still loading
  }

  return (
    <Modal
      isOpen={open}
      onRequestClose={onClose}
      contentLabel="Package Services"
      className="bg-white p-8 rounded shadow-lg w-[40rem] sm:w-[30rem] md:w-[40rem] lg:w-[50rem] mx-auto"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
    >
      <div className="flex flex-col">
        <div className="flex justify-between items-center w-full mb-4">
          <h2 className="text-2xl text-center font-bold" style={{ color: colors.primary[200] }}>
            {packageName}
          </h2>
          <h4 className="text-xl font-bold" style={{ color: colors.primary[200] }}>₹{packagePrice}</h4>
        </div>

        <div className="mb-4 text-right text-lg font-bold" style={{ color: colors.primary[200] }}>
          Selected Services: {selectedServices.length}
        </div>
      </div>

      <hr />
      <div className="flex justify-between">
        {/* Render services list with checkboxes */}
        <div className="mb-4 w-full">
          <h3 className="text-lg font-semibold mb-2" style={{ color: colors.primary[200] }}>Select Services</h3>

          {/* Loading state */}
          {loading && <p>Loading services...</p>}
          {error && <p className="text-red-500">{error}</p>}

          <div className="space-y-2 max-h-[200px] overflow-y-scroll" style={{ maxHeight: "200px" }}>
            {allServices.length > 0 ? (
              allServices.map((service) => (
                <div key={service.service_id} className="flex items-center">
                  <input
                    type="checkbox"
                    checked={selectedServices.some((selected) => selected.service_id === service.service_id)} // Check if service is selected
                    onChange={() => handleCheckboxChange(service.service_id)}
                    className="mr-2"
                  />
                  {/* Image */}
                  <img
                    src={`${API_URLS.getServiceImages.url}/${service.service_image2}`}
                    alt={service.service_name}
                    className="w-10 h-10 object-cover mr-2 rounded"
                    style={{ borderRadius: '8px' }}
                  />
                  {/* Service Name and Price */}
                  <span style={{ color: colors.primary[500] }}>
                    {service.service_name} - ₹{service.service_price}
                  </span>
                </div>
              ))
            ) : (
              <p>No services available</p>
            )}
          </div>
        </div>

        {/* Display selected services */}
        <div className="mb-4 w-full">
          <h3 className="text-lg font-semibold mb-2" style={{ color: colors.primary[200] }}>
            Selected Services
          </h3>

          <div className="space-y-2">
            {selectedServices.length > 0 ? (
              selectedServices.map((service) => (
                <div key={service.service_id} className="flex items-center">
                  {/* Display image of selected service */}
                  <img
                    src={`${API_URLS.getServiceImages.url}/${service.service_image2}`}
                    alt={service.service_name}
                    className="w-10 h-10 object-cover mr-2 rounded"
                    style={{ borderRadius: '8px' }}
                  />
                  <span style={{ color: colors.primary[500] }}>
                    {service.service_name} - ₹{service.service_price}
                  </span>
                </div>
              ))
            ) : (
              <p>No selected services</p>
            )}
          </div>
        </div>
      </div>

      {/* Save or Update Button */}
      <div className="mt-4">
        <button
          onClick={handleSaveOrUpdate}
          className="px-6 py-2 bg-blue-500 text-white rounded shadow-md hover:bg-blue-600 transition"
        >
          Update
        </button>
      </div>
    </Modal>
  );
};

export default PackageServiceModal;
