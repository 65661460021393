import React, { useEffect, useState } from "react";
import { Box, IconButton } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { tokens } from "../theme";
import API_URLS from "../summaryapi/apiUrls";

const CustomersPage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode) || {};
  const blueAccent700 = colors.blueAccent?.[700] || "#0d47a1";

  const [customers, setCustomers] = useState([]);

  // Fetch customer data on component mount
  useEffect(() => {
    fetch(API_URLS.getActiveCustomers.url)
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          setCustomers(data.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching customer data:", error);
      });
  }, []);

  const columns = [
    {
      field: "customer_id",
      headerName: "S.NO",
      flex: 0.2,
      hide: true,
    },
    {
      field: "profile_picture",
      headerName: "Profile Picture",
      renderCell: (params) => (
        <img
          src={params.row.profile_picture ? `${API_URLS.getProviderImages.url}${params.row.profile_picture}` : "defaultProfilePic.jpg"}
          alt="Profile"
          style={{ width: 50, height: 50, objectFit: "cover", margin: "0 auto" }} // Centering the image
        />
      ),
      flex: 0.3,
      headerAlign: "center", // Centering header
      align: "center", // Centering cell data
    },
    {
      field: "full_name",
      headerName: "Full Name",
      flex: 0.3,
      headerAlign: "center", // Centering header
      align: "center", // Centering cell data
    },
    {
      field: "mobile_number",
      headerName: "Mobile Number",
      flex: 0.2,
      headerAlign: "center", // Centering header
      align: "center", // Centering cell data
    },
    {
      field: "email",
      headerName: "Email",
      flex: 0.3,
      headerAlign: "center", // Centering header
      align: "center", // Centering cell data
    },
    {
      field: "address",
      headerName: "Address",
      flex: 0.3,
      headerAlign: "center", // Centering header
      align: "center", // Centering cell data
    },
    {
      field: "is_active",
      headerName: "Active",
      renderCell: (params) => (params.value == "1" ? "Yes" : "No"),
      flex: 0.2,
      headerAlign: "center", // Centering header
      align: "center", // Centering cell data
    },
  ];


  const rows = customers.map((customer) => ({
    id: customer.customer_id,
    customer_id: customer.customer_id,
    full_name: customer.full_name,
    mobile_number: customer.mobile_number,
    email: customer.email,
    address: customer.address,
    profile_picture: customer.profile_picture,
    is_active: customer.is_active,
  }));

  return (
    <Box m="20px">
      <Box
        m="30px 0 0 0"
        height="500px"
        marginTop={20}
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
            fontSize: "14px", // Slightly increased font size
            textAlign: "center", // Ensuring cell data is centered
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: blueAccent700,
            borderBottom: "none",
            fontFamily: '"Poppins", sans-serif',
            fontWeight: "bold",
            fontSize: "16px",
            textAlign: "center", // Ensuring headers are centered
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary?.[400] || "#b3e5fc",
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: blueAccent700,
          },
        }}
      >
        <div style={{ height: 600, width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            disableSelectionOnClick
          />
        </div>
      </Box>
    </Box>
  );
};

export default CustomersPage;
