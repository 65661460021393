// const BASE_URL = "http://192.168.1.138/mech_guru_live";
const BASE_URL = "https://mechguru.oakyaitservice.com/mechguru_api"
const API_URLS = {

//images paths
  getCategoryImages: {
    url: `${BASE_URL}/category/`
  },

  getServiceImages: {
    url: `${BASE_URL}/service/`
  },

  getPackageImages: {
    url: `${BASE_URL}/package/`
  },

  getproviderImages:{
    url: `${BASE_URL}/service_provider/`
  },

//login details

adminLogin:{
  url: `${BASE_URL}/admin/login.php`
},


//category api's
  createcategory: {
    url: `${BASE_URL}/category/create.php`,
    method: 'POST',
  },
  getAllCategories: {
    url: `${BASE_URL}/category/admincategory.php`,
    method: 'GET',
  },
  updateServiceCategory: {
    url: `${BASE_URL}/category/update.php`,
    method: 'POST',
  },

//service api's
  createService: {
    url: `${BASE_URL}/service/create.php`,
    method: 'POST',
  },
  getAllServices: {
    url: `${BASE_URL}/service/adminservice.php`,
    method: 'GET',
  },
  updateServices: {
    url: `${BASE_URL}/service/update.php`,
    method: 'POST',
  },

//packages api's
getAllAdminPackages: {
  url: `${BASE_URL}/package/adminpackage.php`,
  method: 'GET',
},

createPackages: {
  url: `${BASE_URL}/package/create.php`,
  method: 'POST',
},
updatePackages: {
  url: `${BASE_URL}/package/update.php`,
  method: 'POST',
},

//package service api's

getAllPackageServices: {
  url: `${BASE_URL}/package_services/retrieve.php`,
  method: 'POST',
},
createPackagesServices: {
  url: `${BASE_URL}/package_services/create.php`,
  method: 'POST',
},
updatePackagesServices: {
  url: `${BASE_URL}/package_services/update.php`,
  method: 'POST',
},
getActiveServices:{
  url: `${BASE_URL}/service/retrieve.php`,
  method: 'GET',
},

getPackageServices:{
  url: `${BASE_URL}/package_services/retrieve.php`,
  method: 'PUT',
},

//service providers api's
getAllServiceProviders: {
  url: `${BASE_URL}/service_provider/admin_service_provider.php`,
  method: 'GET',
},
getProviderDetailsById: {
  url: `${BASE_URL}/service_provider/admin_service_provider.php`,
  method: 'POST',
},

getProviderBookingsById:{
  url: `${BASE_URL}/customer_service_booking_details/today.php`,
  method: 'POST',
},

CreateServiceProvider:{
  url: `${BASE_URL}/service_provider/create.php`,
  method: 'POST',
},
UpdateServiceProvider:{
  url: `${BASE_URL}/service_provider/update.php`,
  method: 'POST',
},


AvialableServiceProviders:{
  url: `${BASE_URL}/service_provider/retrieve.php`,
  method: 'PUT',
},

//Booking data
getAllBookingData:{
  url: `${BASE_URL}/customer_service_booking_details/retrieve.php`,
  method: 'GET',
},

getTodayBookingData:{
  url: `${BASE_URL}/customer_service_booking_details/today.php`,
  method: 'GET',
},
//analytics

getAnalyticsData:{
  url: `${BASE_URL}/analytics/count.php`,
  method: 'GET',
},

getActiveCustomers:{
url: `${BASE_URL}/customer/retrieve.php`,
method: 'GET',
},


};
export default API_URLS;
