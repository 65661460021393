import React, { useEffect, useState } from 'react';
import { Grid, Box, Card, useTheme,Typography } from '@mui/material';
import PieChart from './PieChart';  // Assuming you have a PieChart component
import BarChart from './BarChart';  // Assuming you have a BarChart component
import axios from 'axios';  // Import Axios for API requests
import API_URLS from '../summaryapi/apiUrls'; 

const Charts = () => {
  const theme = useTheme(); // Use theme to get colors from Material UI

  // Define very light grey and light grey hover color
  const cardBgColor = '#F5F5F5';  // Very light grey color for the card background
  const hoverBgColor = '#E0E0E0';  // Slightly darker grey for hover effect
  const cardShadow = '0px 4px 8px rgba(0, 0, 0, 0.1)'; // Subtle shadow for the cards

  const [analyticsData, setAnalyticsData] = useState({
    active_customers: 0,
    active_service_providers: 0,
    weekly_bookings: 0,
    monthly_bookings: 0,
  });

  const [weeklyBookingData, setWeeklyBookingData] = useState([]);

  // Fetch analytics data and weekly bookings data from the backend
  useEffect(() => {
    const fetchAnalyticsData = async () => {
      try {
        const response = await axios.get(API_URLS.getAnalyticsData.url);
        setAnalyticsData(response.data.data);
      } catch (error) {
        console.error('Error fetching analytics data:', error);
      }
    };

    const fetchWeeklyBookingData = async () => {
      try {
        const response = await axios.get(API_URLS.getAllBookingData.url);
        const bookings = response.data.data;

        // Initialize an array with zero bookings for each day of the week
        let weeklyBookings = [0, 0, 0, 0, 0, 0, 0]; // Sunday to Saturday

        bookings.forEach(booking => {
          const bookingDate = new Date(booking.created_date);
          const dayOfWeek = bookingDate.getDay(); // Get day of the week (0 = Sunday, 6 = Saturday)
          weeklyBookings[dayOfWeek]++;
        });

        setWeeklyBookingData(weeklyBookings);
      } catch (error) {
        console.error('Error fetching weekly booking data:', error);
      }
    };

    fetchAnalyticsData();
    fetchWeeklyBookingData();
  }, []);

  // Prepare the data for the PieChart and BarChart based on fetched data
  const pieChartData = [
    { id: 'Customers', value: parseInt(analyticsData.active_customers) },
    { id: 'Service Providers', value: parseInt(analyticsData.active_service_providers) },
  ];

  // Prepare data for BarChart (weekly bookings)
  const barChartData = weeklyBookingData.map((bookings, index) => ({
    day: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][index],  // Convert index to day name
    bookings,
  }));

  return (
    <Grid container spacing={4}>
      {/* Pie Chart Card */}
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <Card
          sx={{
            backgroundColor: cardBgColor,
            boxShadow: cardShadow,
            borderRadius: 2,
            '&:hover': {
              backgroundColor: hoverBgColor,
              boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.2)',
            },
            transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
          }}
        >
          <Box sx={{ height: '340px' }} p={2}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
        Active Connections
      </Typography>
            <PieChart data={pieChartData} />
          </Box>
        </Card>
      </Grid>

      {/* Bar Chart Card for Weekly Bookings */}
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <Card
          sx={{
            backgroundColor: cardBgColor,
            boxShadow: cardShadow,
            borderRadius: 2,
            '&:hover': {
              backgroundColor: hoverBgColor,
              boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.2)',
            },
            transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
          }}
        >
    <Box sx={{ height: '340px' }} >
      {/* Booking Report Heading */}
      <Typography variant="h6" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
        Booking Report
      </Typography>
            <BarChart data={barChartData} />
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Charts;
