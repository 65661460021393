import React, { useState, useEffect } from "react";
import { Button, Typography, Box } from "@mui/material";
import ServiceModal from "../components/ServiceModal"; // Import the ServiceModal component
import { MdModeEdit } from "react-icons/md";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../theme"; // Ensure tokens are imported correctly
import axios from "axios";
import API_URLS from "../summaryapi/apiUrls";
import { toast } from 'react-toastify';

const Services = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode); // Get the colors based on current theme mode

  const [services, setServices] = useState([]); // Stores fetched services
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newService, setNewService] = useState({
    service_name: "",
    service_image1: null,
    service_image2: null,
    service_description: "",
    service_subtitle: "",
    service_price: "",
    vehicle_type: '2-wheeler', // Default vehicle type set to '2-wheeler'
    is_active: false, // Ensure this is a boolean
    service_id: null,
  });

  const [editingService, setEditingService] = useState(null); // Track the service being edited

  // Fetch all services from the backend when the component mounts
  useEffect(() => {
    fetchServices();
  }, []);

  // Fetch services from the backend
  const fetchServices = async () => {
    try {
      const response = await axios.get(API_URLS.getAllServices.url);
      const services = response.data.data; // Assuming the response data is an array of services
  
      // Sort services by created_date in descending order
      const sortedServices = services.sort((a, b) => {
        const dateA = new Date(a.created_date);
        const dateB = new Date(b.created_date);
        return dateB - dateA; // Sorting in descending order
      });
  
      setServices(sortedServices); // Set the sorted services
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };
  
  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add("overflow-hidden"); // Lock body scroll
    } else {
      document.body.classList.remove("overflow-hidden"); // Unlock body scroll
    }

    // Clean up on component unmount or modal close
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isModalOpen]); // Runs every time modalOpen changes



  const openModal = () => {
    setEditingService(null); // Reset the editing service when "Add Service" button is clicked
    setNewService({
      service_name: "",
      service_image1: null,
      service_image2: null,
      service_description: "",
      service_subtitle: "",
      service_price: "",
      vehicle_type: '2-wheeler', // Default to '2-wheeler'
      is_active: true,
      service_id: null, // Reset service id when adding new service
    });
    setIsModalOpen(true); // Open modal for adding new service
  };

  const openEditModal = (service) => {
    setEditingService(service); // Set the service being edited
    setNewService({
      ...service, // This should include `service_id` if available
      service_image1: null, // Reset image for editing (so it doesn't overwrite the current one unless a new image is uploaded)
      service_image2: null, // Reset image for editing
    });
    setIsModalOpen(true); // Open modal for editing service
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setNewService({
      service_name: "",
      service_image1: null,
      service_image2: null,
      service_description: "",
      service_subtitle: "",
      service_price: "",
      is_active: false,
      service_id: null, // Reset service id after closing modal
    });
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setNewService((prevState) => ({
        ...prevState,
        [name]: checked, // Checkbox input, store as boolean
      }));
    } else {
      setNewService((prevState) => ({
        ...prevState,
        [name]: name === "is_active" ? checked : value, // Ensure `is_active` is stored as boolean
      }));
    }
  };

  const handleImageChange = (e, imageField) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file); // To show image preview
      setNewService((prevState) => ({
        ...prevState,
        [imageField]: file, // Store the file object in the specific field
        [`${imageField}_url`]: imageUrl, // Store the URL for image preview
      }));
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Prepare the FormData for both create and update operations
    const formData = new FormData();
    formData.append("service_name", newService.service_name);
    formData.append("service_description", newService.service_description);
    formData.append("service_subtitle", newService.service_subtitle);
    formData.append("service_price", newService.service_price);
    formData.append("vehicle_type", newService.vehicle_type);
    formData.append("is_active", newService.is_active ? "1" : "0"); // Convert to "1" or "0"
  
    // If an image is provided, append it to the FormData
    if (newService.service_image1) {
      formData.append("service_image1", newService.service_image1);
    }
    if (newService.service_image2) {
      formData.append("service_image2", newService.service_image2);
    }
  
    // Pass service_id directly if editing an existing service
    if (editingService && editingService.service_id) {
      formData.append("service_id", editingService.service_id); // Append service_id for update
    } else {
      console.log("New service is being added");
    }
  
    try {
      let response;
      
      // Determine the URL and method based on whether we're updating or creating a service
      const apiUrl = editingService ? API_URLS.updateServices.url : API_URLS.createService.url;
      const method = editingService ? API_URLS.updateServices.method : API_URLS.createService.method;
  
      // Make the API request using fetch or axios (you can choose)
      response = await fetch(apiUrl, {
        method: method,
        body: formData,
      });
  
      const result = await response.json();
      if (result.status) {
        fetchServices(); // Refresh the service list after the update/create operation
        toast.success(editingService ? "Service updated successfully!" : "Service added successfully!");
      } else {
        console.error("Failed to save service:", result.message);
      }
  
    } catch (err) {
      console.error("Error saving service:", err);
    }
  
    // Close the modal and reset the form after submission
    setIsModalOpen(false);
    setNewService({
      service_name: "",
      service_image1: null,
      service_image2: null,
      service_description: "",
      service_subtitle: "",
      service_price: "",
      is_active: false,
      service_id: null, // Reset service_id after submission
    });
  };

  return (
    <Box m="20px"         marginTop={3}>
      {/* Page Title */}
      <Typography
        variant="h4"
        component="h1"
        sx={{ color: colors.primary[100], textAlign: "center", marginBottom: 4 }}
      >
        <h1 className="font-poppins font-bold text-center text-[30px]">Services</h1>
      </Typography>

      {/* Add Service Button */}
      <div className="flex justify-end mb-[16px] w-[95%]">
        <Button
          variant="contained"
          onClick={openModal}
          color="success"
          size="large"
          sx={{
            width: "150px",
            textTransform: "none",
            backgroundColor: colors.greenAccent[500],
            color: "#fff",
            fontWeight: "bold",
            "&:hover": {
              backgroundColor: colors.greenAccent[700],
            },
          }}
        >
          Add Service
        </Button>
      </div>

      {/* Service Table */}
      <div className="overflow-auto mb-4 max-h-[600px]">
        <table className="min-w-full table-auto border-separate" style={{ borderSpacing: "0" }}>
        <thead>
  <tr
    style={{
      backgroundColor: colors.blueAccent[700],
      color: colors.primary[100],
      fontFamily: '"Source Sans Pro", sans-serif',
      fontWeight: "bold",
    }}
  >
    <th className="px-6 py-3 border-b text-center">Image</th>
    <th className="px-6 py-3 border-b text-center">Image</th>
    <th className="px-6 py-3 border-b text-center">Service</th>
    <th className="px-6 py-3 border-b text-center">Description</th>
    <th className="px-6 py-3 border-b text-center">Subtitle</th>
    <th className="px-6 py-3 border-b text-center">Price</th>
    <th className="px-6 py-3 border-b text-center">Status</th>
    <th className="px-6 py-3 border-b text-center">Actions</th>
  </tr>
</thead>

          <tbody>
  {services.map((service) => (
    <tr
      key={service.service_id}
      className="hover:bg-gray-50"
      style={{ backgroundColor: colors.primary[400] }}
    >
      {/* Image 1 */}
      <td className="px-6 py-4 border-b text-center">
        <img
          src={`${API_URLS.getServiceImages.url}${service.service_image1}`}
          alt={service.service_name}
          className="w-16 h-16 object-cover mx-auto" // Added `mx-auto` for horizontal centering
        />
      </td>

      {/* Image 2 */}
      <td className="px-6 py-4 border-b text-center">
        <img
          src={`${API_URLS.getServiceImages.url}${service.service_image2}`}
          alt={service.service_name}
          className="w-16 h-16 object-cover mx-auto"
        />
      </td>

      {/* Service Name */}
      <td className="px-6 py-4 border-b text-center">
        {service.service_name}
      </td>

      {/* Description */}
      <td className="px-6 py-4 border-b text-center">
        {service.service_description}
      </td>

      {/* Subtitle */}
      <td className="px-6 py-4 border-b text-center">
        {service.service_subtitle}
      </td>

      {/* Price */}
      <td className="px-6 py-4 border-b text-center">
        ₹{parseFloat(service.service_price).toFixed(2)}
      </td>

      {/* Status */}
      <td className="px-6 py-4 border-b text-center">
        <span
          className={`px-2 py-1 text-white rounded-full ${service.is_active === "1" ? 'bg-green-500' : 'bg-red-500'}`}
        >
          {service.is_active === "1" ? 'Active' : 'Inactive'}
        </span>
      </td>

      {/* Actions */}
      <td className="px-6 py-4 border-b text-center">
        <Button
          onClick={() => openEditModal(service)}
          sx={{
            backgroundColor: colors.blueAccent[500],
            color: "white",
            padding: "8px 12px",
            borderRadius: "8px",
            marginRight: "8px",
            "&:hover": {
              backgroundColor: colors.blueAccent[700],
            },
          }}
        >
          <MdModeEdit />
        </Button>
      </td>
    </tr>
  ))}
</tbody>

        </table>
      </div>

      {/* Pass props to ServiceModal */}
      <ServiceModal
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        handleChange={handleChange}
        handleImageChange={handleImageChange}
        handleSubmit={handleSubmit}
        newService={newService}
        editingService={editingService}
      />
    </Box>
  );
};

export default Services;
