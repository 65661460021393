import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, TextField, Button, Typography, InputAdornment } from "@mui/material";
import { AiOutlineUser, AiOutlineLock } from "react-icons/ai";
import axios from "axios";
import API_URLS from "../../summaryapi/apiUrls";
import "./Login.css";

const Login = () => {
  const [name, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState({ name: false, password: false });
  const navigate = useNavigate();

  const handleLogin = async () => {
    setError({ name: false, password: false });
    if (!name) {
      setError((prev) => ({ ...prev, name: true }));
      return;
    }
    if (!password) {
      setError((prev) => ({ ...prev, password: true }));
      return;
    }

    const requestData = { name, password };

    try {
      const response = await axios.post(API_URLS.adminLogin.url, requestData);

      if (response.data.status) {
        const adminId = response.data.admin_id;
        sessionStorage.setItem("admin_id", btoa(adminId));
        navigate("/");
      } else {
        alert("Invalid credentials");
      }
    } catch (error) {
      console.error("Login error:", error);
      alert("An error occurred during login. Please try again.");
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();  // Prevent form submission when Enter is pressed
    }
  };
  return (
    <Box className="login-container">
      <Box className="login-box">
        <Typography variant="h4" gutterBottom>
          Admin Login
        </Typography>
        <TextField
  label="Username"
  value={name}
  onChange={(e) => {
    setUsername(e.target.value);
    setError((prevError) => ({ ...prevError, name: false }));
  }}
  margin="normal"
  fullWidth
  error={error.name}
  helperText={error.name ? "Username is required" : ""}
  InputProps={{
    startAdornment: (
      <InputAdornment position="start">
        <AiOutlineUser style={{ color: "#76ff03" }} />
      </InputAdornment>
    ),
    style: { color: 'white' }, // Add this line to change text color to white
  }}
  variant="outlined"
/>

<TextField
  label="Password"
  type="password"
  value={password}
  onChange={(e) => {
    setPassword(e.target.value);
    setError((prevError) => ({ ...prevError, password: false }));
  }}
  margin="normal"
  fullWidth
  error={error.password}
  helperText={error.password ? "Password is required" : ""}
  InputProps={{
    startAdornment: (
      <InputAdornment position="start">
        <AiOutlineLock style={{ color: "#76ff03" }} />
      </InputAdornment>
    ),
    style: { color: 'white' }, // Add this line to change text color to white
  }}
  variant="outlined"
/>

        <Button
          variant="contained"
          color="success"
          onClick={handleLogin}
          fullWidth
          sx={{ mt: 5 }}
        >
          Login
        </Button>
      </Box>
    </Box>
  );
};

export default Login;
