import React from "react";
import { Box, IconButton } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { tokens } from "../theme";
import API_URLS from "../summaryapi/apiUrls";

const CategoryReportForm = ({ categories, onEditCategory }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode) || {};
  const blueAccent700 = colors.blueAccent?.[700] || "#0d47a1";

  const columns = [
    {
      field: "category_id ",
      headerName: "S.NO",
      flex: 0.2,
      hide: true,
    },
    {
      field: "category_image",
      headerName: "Image",
      renderCell: (params) => (
        <img
          src={`${API_URLS.getCategoryImages.url}${params.row.category_image}`}
          alt="Category"
          style={{ width: 50, height: 50, objectFit: "cover", margin: "0 auto" }} // Centering the image
        />
      ),
      flex: 0.3,
      headerAlign: "center",  // Centering header
      align: "center", // Centering cell data
    },
    {
      field: "category_name",
      headerName: "Category Name",
      flex: 0.3,
      headerAlign: "center", // Centering header
      align: "center", // Centering cell data
    },
    {
      field: "is_active",
      headerName: "Active",
      renderCell: (params) => (params.value === "1" ? "Yes" : "No"),
      flex: 0.2,
      headerAlign: "center", // Centering header
      align: "center", // Centering cell data
    },
    {
      field: "edit",
      headerName: "Edit",
      renderCell: (params) => (
        <IconButton
          onClick={() => onEditCategory(params.row)}
          style={{ color: blueAccent700 }}
        >
          <EditIcon />
        </IconButton>
      ),
      flex: 0.2,
      headerAlign: "center", // Centering header
      align: "center", // Centering cell data
    },
  ];

  const rows = categories.map((category) => ({
    id: category.category_id,
    category_id: category.category_id,
    category_name: category.category_name,
    category_image: category.category_image,
    is_active: category.is_active,
  }));

  return (
    <Box m="20px">
      <Box
        m="30px 0 0 0"
        height="500px"
        marginTop={10}
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
            fontSize: "14px", // Slightly increased font size
            textAlign: "center", // Ensuring cell data is centered
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: blueAccent700,
            borderBottom: "none",
            fontFamily: '"Poppins", sans-serif',
            fontWeight: "bold",
            fontSize: "16px",
            textAlign: "center", // Ensuring headers are centered
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary?.[400] || "#b3e5fc",
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: blueAccent700,
          },
        }}
      >
        <div style={{ height: 600, width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            disableSelectionOnClick
          />
        </div>
      </Box>
    </Box>
  );
};


export default CategoryReportForm;