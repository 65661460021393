import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button,Typography } from "@mui/material";
import { Add } from "@mui/icons-material";
import ServiceProviderModal from "../components/ServiceProviderModal";
import ServiceProvider from "../components/ServiceProvider";
import API_URLS from "../summaryapi/apiUrls";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../theme";
const ServiceProvidersList = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [serviceProviders, setServiceProviders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [statusMessage, setStatusMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState(null);

  const fetchServiceProviders = async () => {
    try {
      const response = await axios.get(API_URLS.getAllServiceProviders.url);
      if (response.data.status === false) {
        setStatusMessage("No service providers found.");
      } else {
        setServiceProviders(response.data.data || []); // Ensure serviceProviders is always an array
      }
      setLoading(false);
    } catch (err) {
      setError("Failed to fetch service providers.");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchServiceProviders();
  }, []);
  
  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add("overflow-hidden"); // Lock body scroll
    } else {
      document.body.classList.remove("overflow-hidden"); // Unlock body scroll
    }

    // Clean up on component unmount or modal close
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isModalOpen]); // Runs every time modalOpen changes
  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedProvider(null); // Reset provider on close
  };

  const handleModalOpen = (provider) => {
    if (provider) {
      // If the provider is selected for editing, populate the form with its data
      setSelectedProvider(provider);
    } else {
      // If it's a new provider, reset the form data
      setSelectedProvider(null);
    }
    setIsModalOpen(true);
  };
  

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="relative m-4">
            <Typography
        variant="h4"
        component="h1"
        sx={{ color: colors.primary[100], textAlign: "center", marginBottom: 4 }}
      >
        <h1 className="font-poppins font-bold text-center text-[30px]">Service Providers</h1>
      </Typography>
      <Button
        variant="contained"
        color="primary"
        sx={{
          position: "fixed",
          top: "20px",
          right: "20px",
          marginTop: "100px",
          zIndex: 9999,
        }}
        onClick={() => handleModalOpen(null)} // Open modal for adding
      >
        <Add /> Add Service Provider
      </Button>

      {statusMessage ? (
        <div className="flex items-center justify-center h-screen">
          <span className="text-xl text-gray-500">{statusMessage}</span>
        </div>
      ) : (
        <div className="flex flex-col">
          <div
            className="overflow-y-auto"
            style={{ maxHeight: "calc(100vh - 120px)", marginTop: "120px" }}
          >
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
              {/* Safely map over serviceProviders array */}
              {serviceProviders && Array.isArray(serviceProviders) && serviceProviders.map((provider) => {
                if (!provider || !provider.service_provider_id) return null; // Avoid rendering invalid provider
                return (
                  <div
                    key={provider.service_provider_id} // Ensure key exists
                    className="service-provider-card p-4 border rounded-lg shadow-lg flex flex-col justify-between"
                    style={{
                      width: "90%",
                      height: "600px",
                    }}
                  >
                    <ServiceProvider {...provider} />
                    <div className="text-center">
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => handleModalOpen(provider)}
                      >
                        Modify Service Provider
                      </Button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}

      {/* Pass the selected provider data to the modal */}
      <ServiceProviderModal
        open={isModalOpen}
        onClose={handleModalClose}
        provider={selectedProvider}
        onSubmit={(newProvider) => {
          if (selectedProvider) {
            setServiceProviders(
              serviceProviders.map((provider) =>
                provider?.service_provider_id === newProvider?.service_provider_id
                  ? newProvider
                  : provider
              )
            );
          } else {
            setServiceProviders([...serviceProviders, newProvider]);
          }
          handleModalClose();
        }}
        fetchServiceProviders={fetchServiceProviders}
      />
    </div>
  );
};

export default ServiceProvidersList;
