import React from "react";
import Modal from "react-modal";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../theme";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import Switch from '@mui/material/Switch';

// Set the root element for the modal
Modal.setAppElement("#root");

const PackageModal = ({
  open,
  onClose,
  onSave,
  newPackage,
  handleInputChange,
  handleImageChange,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // Form submit handler
  const handleFormSubmit = (e) => {
    e.preventDefault();
    onSave(); // Trigger the onSave function passed from the parent
  };
  const modalHeightClass = newPackage ? 'max-h-[calc(100vh-20px)]' : 'max-h-[calc(100vh-20px)]';  

  return (
    <Modal
      isOpen={open}
      onRequestClose={onClose}
      contentLabel="Add/Edit Package"
      className={`bg-white p-8 rounded shadow-lg w-[60vw] sm:w-[40vw] md:w-[30vw] mx-auto ${modalHeightClass} overflow-y-auto`}
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
    >
      <h2
        className="text-2xl mb-4 text-center font-bold"
        style={{ color: colors.primary[100] }}
      >
        {newPackage.package_id ? "Edit Package" : "Add New Package"}
      </h2>
      <form onSubmit={handleFormSubmit}>
        {/* Vehicle Type Radio Buttons */}
        <div className="mb-4">
          <FormControl component="fieldset" fullWidth>
            <FormLabel
              component="legend"
              style={{ color: colors.primary[200], fontWeight: 700 }}
              className="block font-bold"
            >
              Vehicle Type
            </FormLabel>
            <RadioGroup
              name="vehicle_type"
              value={newPackage.vehicle_type || "2-wheeler"} // Default to "2-wheeler" if no value is set
              onChange={handleInputChange}
              row
            >
              <FormControlLabel
                value="2-wheeler"
                control={<Radio />}
                label="2-Wheeler"
                style={{ color: colors.primary[100] }}
              />
              <FormControlLabel
                value="4-wheeler"
                control={<Radio />}
                label="4-Wheeler"
                style={{ color: colors.primary[100] }}
              />
            </RadioGroup>
          </FormControl>
        </div>

        {/* Package Name and Subtitle in One Row */}
        <div className="mb-4 flex space-x-4">
          <div className="w-1/2">
            <label className="block mb-2 font-bold" style={{ color: colors.primary[200] }}>
              Package Name
            </label>
            <input
              type="text"
              name="package_name"
              placeholder="Package Name"
              value={newPackage.package_name}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded"
              style={{
                borderColor: colors.primary[300],
                backgroundColor: colors.primary[400],
                color: colors.primary[100],
              }}
              required
            />
          </div>

          <div className="w-1/2">
            <label className="block mb-2 font-bold" style={{ color: colors.primary[200] }}>
              Subtitle
            </label>
            <input
              type="text"
              placeholder="Package Subtitle"
              name="package_subtitle"
              value={newPackage.package_subtitle}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded"
              style={{
                borderColor: colors.primary[300],
                backgroundColor: colors.primary[400],
                color: colors.primary[100],
              }}
              required
            />
          </div>
        </div>

        {/* Package Description */}
        <div className="mb-4">
          <label className="block mb-2 font-bold" style={{ color: colors.primary[200] }}>
            Description
          </label>
          <textarea
            placeholder="Package Description"
            name="package_description"
            value={newPackage.package_description}
            onChange={handleInputChange}
            className="w-full px-3 py-2 border rounded"
            style={{
              borderColor: colors.primary[300],
              backgroundColor: colors.primary[400],
              color: colors.primary[100],
            }}
            required
          />
        </div>

        {/* Package Price */}
        <div className="mb-4">
          <label className="block mb-2 font-bold" style={{ color: colors.primary[200] }}>
            Price
          </label>
          <input
            placeholder="Package Price"
            type="number"
            name="package_price"
            value={newPackage.package_price}
            onChange={handleInputChange}
            className="w-full px-3 py-2 border rounded"
            style={{
              borderColor: colors.primary[300],
              backgroundColor: colors.primary[400],
              color: colors.primary[100],
            }}
            required
          />
        </div>

        {/* Image Uploads in Row */}
        <div className="mb-4 flex space-x-4">
          {/* Image Upload for Image 1 */}
          <div className="w-1/2">
            <label className="block mb-2 font-bold" style={{ color: colors.primary[200] }}>
              Image Upload 1
            </label>
            <input
              type="file"
              name="package_image1"
              onChange={(e) => handleImageChange(e, 'package_image1')}
              accept="image/*"
              className="w-full px-3 py-2 border rounded"
            />
            {newPackage.package_image1_url && (
              <div className="mt-2">
                <img
                  src={newPackage.package_image1_url}
                  alt="Preview 1"
                  className="w-20 h-20 object-cover"
                />
              </div>
            )}
          </div>

          {/* Image Upload for Image 2 */}
          <div className="w-1/2">
            <label className="block mb-2 font-bold" style={{ color: colors.primary[200] }}>
              Image Upload 2
            </label>
            <input
              type="file"
              name="package_image2"
              onChange={(e) => handleImageChange(e, 'package_image2')}
              accept="image/*"
              className="w-full px-3 py-2 border rounded"
            />
            {newPackage.package_image2_url && (
              <div className="mt-2">
                <img
                  src={newPackage.package_image2_url}
                  alt="Preview 2"
                  className="w-20 h-20 object-cover"
                />
              </div>
            )}
          </div>
        </div>

        {/* Active Status */}
        <div className="mb-4 flex items-center">
          <label className="block font-bold" style={{ color: colors.primary[200] }}>
            Active
          </label>
          <Switch
            name="is_active"
            checked={newPackage.is_active}
            onChange={handleInputChange}
            style={{
              color: colors.greenAccent[500],
            }}
          />
        </div>

        {/* Modal Actions */}
        <div className="flex justify-between">
          <button
            type="button"
            onClick={onClose}
            className="px-4 py-2 rounded"
            style={{
              backgroundColor: "#e0e0e0",
              color: colors.primary[500],
            }}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="px-4 py-2 rounded"
            style={{
              backgroundColor: "#4caf50",
              color: colors.primary[101],
              fontWeight: "bold",
            }}
          >
            {newPackage.package_id ? "Update" : "Add"}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default PackageModal;
