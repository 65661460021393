import React, { useEffect, useState } from 'react';
import API_URLS from "../summaryapi/apiUrls";  // Assuming your API_URLS file is set up correctly
import { FiEye } from 'react-icons/fi'; // Importing the Eye icon from react-icons
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Box, Typography, Grid } from '@mui/material';
import { useTheme } from "@mui/material";
import { tokens } from "../theme";  // Assuming tokens are for color styling
import axios from 'axios';

// Modal Component for Service Details
const Modal = ({ show, onClose, services }) => {
    const theme = useTheme();  // Ensure useTheme is called to access the theme
    const colors = tokens(theme.palette.mode); 
    if (!show) return null;
    return (
      <Dialog
        open={show}
        onClose={onClose}
        maxWidth="md"
        fullWidth
        sx={{
          zIndex: 1500,  // Make sure it's above the top bar and other content
        }}
      >
        <DialogTitle className="text-xl font-semibold text-center">
          Service Details
        </DialogTitle>
        <DialogContent className="p-6 space-y-4">
          {services && (
            <Grid container spacing={4} justifyContent="center">
  {/* Loop over all services in the package or just one service */}
  {Array.isArray(services) ? (
    services.map((service, idx) => (
      <Grid item xs={12} sm={6} md={5} lg={4} key={idx}> {/* Adjust width to 5/12 (41.6%) on medium screens */}
        <Box className="flex flex-col items-center space-y-4" sx={{ width: '100%' }}>
          {service.service_image2 && (
            <div className="w-full max-w-[350px]">  {/* Increased image size */}
              <img
                src={`${API_URLS.getServiceImages.url}${service.service_image2}`}
                alt={service.service_name}
                className="w-full h-auto rounded-lg shadow-md object-cover"
              />
            </div>
          )}
          <div className="text-center">
            <Typography variant="body1" className="text-lg font-semibold" style={{
              color: colors.primary[100],
              padding: "2px 6px",
              borderRadius: "4px",
              marginRight: "5px",
            }}>
              <strong>{service.service_name}</strong>
            </Typography>
            <Typography variant="body2" style={{
              color: colors.primary[100],
              padding: "2px 6px",
              borderRadius: "4px",
              marginRight: "5px",
            }}>
              ₹{service.service_price}
            </Typography>
          </div>
        </Box>
      </Grid>
    ))
  ) : (
    <Grid item xs={12} sm={6} md={5} lg={4}>
      <Box className="flex flex-col items-center space-y-4" sx={{ width: '100%' }}>
        {services.service_image2 && (
          <div className="w-full max-w-[350px]">  {/* Increased image size */}
            <img
              src={`${API_URLS.getServiceImages.url}${services.service_image2}`}
              alt={services.service_name}
              className="w-full h-auto rounded-lg shadow-md object-cover"
            />
          </div>
        )}
        <div className="text-center">
          <Typography variant="body1" className="text-lg font-semibold" style={{
            backgroundColor: colors.redAccent[700],
            color: colors.primary[100],
            padding: "2px 6px",
            borderRadius: "4px",
            marginRight: "5px",
          }}>
            <strong>{services.service_name}</strong>
          </Typography>
          <Typography variant="body2" style={{
            backgroundColor: colors.redAccent[700],
            color: colors.primary[100],
            padding: "2px 6px",
            borderRadius: "4px",
            marginRight: "5px",
          }}>
            ₹{services.service_price}
          </Typography>
        </div>
      </Box>
    </Grid>
  )}
</Grid>

  
         
          )}
        </DialogContent>
        <DialogActions className="p-4">
          <Button
            onClick={onClose}
            color="primary"
            className="bg-blue-500 text-white font-semibold px-6 py-2 rounded-lg hover:bg-red-600"
            sx={{
              backgroundColor: colors.primary[400],
              color: colors.primary[100],
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
};




// Main Component
const AssignBooking = () => {
    const [booking, setBooking] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedServices, setSelectedServices] = useState(null);  // Change here for multiple services
    const [serviceProviders, setServiceProviders] = useState([]);  // Store list of service providers
    const [selectedProviderId, setSelectedProviderId] = useState(null);  // Store the selected provider's ID
    const [providerDetails, setProviderDetails] = useState(null);  // Store details of selected provider
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const fetchServiceProviders = () => {
      setLoading(true);
      setError(null);
    
      axios({
        method: 'PUT',
        url: `${API_URLS.AvialableServiceProviders.url}`,
      })
        .then(response => {
          // Check if the response has the expected structure
          if (response.data.data && Array.isArray(response.data.data)) {
            setServiceProviders(response.data.data);  // Update with the 'data' array from the response
          } else {
            setError('No providers found');
          }
        })
        .catch(error => {
          setError('Failed to fetch service providers');
        })
        .finally(() => {
          setLoading(false);
        });
    };
    

    useEffect(() => {
      // Fetch the list of service providers when component mounts
      fetchServiceProviders();
    }, []);
    useEffect(() => {
      const handleMessage = (event) => {
        if (event.origin !== window.location.origin) {
          return; // Ignore messages from different origins
        }
  
        if (event.data && event.data.booking) {
          setBooking(event.data.booking);
        }
        
      };
  
      window.addEventListener('message', handleMessage);
  
return () => {
  window.removeEventListener('message', handleMessage);
};

    }, []);
  
    console.log(booking)
    if (!booking) {
      return <div>Loading booking details...</div>;
    }
    
    const handleProviderSelect = async (e) => {
      const selectedId = e.target.value;
      setSelectedProviderId(selectedId); // Set the selected provider ID
      try {
        // Create the request payload as an object
        const payload = {
          service_provider_id: selectedId,
        };
    
        // Convert the payload object to a JSON string
        const jsonPayload = JSON.stringify(payload);
    
        // Send the stringified payload in the POST request
        const response = await axios.post(API_URLS.getProviderDetailsById.url, jsonPayload, {
          headers: {
            'Content-Type': 'application/json'  // Ensure correct content type
          }
        });
    
        if (response.data.data) {
          setProviderDetails(response.data.data);  // Update providerDetails with the response data
          console.log(response.data.providerDetails);
        }
      } catch (error) {
        setError('Error fetching provider details');
        console.error('Error fetching provider details:', error);
      }
    };
    
    const handleEyeClick = (serviceOrItemsData) => {
      if (Array.isArray(serviceOrItemsData)) {
        // If package has multiple services, show details for all services in the package
        setSelectedServices(serviceOrItemsData);
      } else {
        // If it's a single service, show details for just that service
        setSelectedServices([serviceOrItemsData]);
      }
      setShowModal(true);
    };
  
    const closeModal = () => {
      setShowModal(false);
      setSelectedServices(null);  // Reset services after modal close
    };
    return (
      <div className="container" style={{ display: 'flex', flexDirection: 'column', width: '100vw', margin: 0, padding: 0 , overflowY: "auto", 
        height: "calc(100vh - 80px)", 
        paddingBottom: "20px", 
        '&::-webkit-scrollbar': {
          display: 'none', 
        },
        scrollbarWidth: 'none', }}>
          <h1 style={{ color: colors.primary[100], padding: "2px 6px", borderRadius: "4px", fontSize:"24px"}}  className="text-center">Assigning Orders</h1>
        {/* Content Area */}
        <div className="content-area" style={{ marginTop: '80px', padding: '20px', flex: 1, width: '100%' }}>
  <div style={{ display: 'flex', justifyContent: 'space-between', gap: '20px', flexWrap: 'wrap' }}>
 
            {/* Booking Information */}
    {/* Booking Information */}
    <div className="card" style={{
  flex: 1,
  maxWidth: '50%',  // Set maxWidth instead of minWidth to avoid taking over full width
  borderRadius: '8px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  padding: '20px',
  backgroundColor: '#f9f9f9',
  marginBottom: '30px',
  minHeight: '200px',
  marginLeft: '5px',
}}>

                        <h3 style={{ color: colors.primary[100], padding: "2px 6px", borderRadius: "4px", fontSize:'18px'}}  className="text-center">Booking Details</h3>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px', alignItems: 'center' }}>
                <strong style={{ color: colors.primary[100], padding: "2px 6px", borderRadius: "4px" }}>Booking Code:</strong>
                <span style={{ display: 'inline-block', padding: '6px 12px', backgroundColor: '#e1f5fe', borderRadius: '8px', fontSize: '14px', fontWeight: 'bold', color: '#0288d1', border: '1px solid #0288d1' }}>
                  {booking.booking_code}
                </span>
              </div>
  
              {/* Service Booked Date */}
              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px', alignItems: 'center' }}>
                <strong style={{ color: colors.primary[100], padding: "2px 6px", borderRadius: "4px" }}>Service Booked Date:</strong>
                <span style={{ color: colors.primary[100], padding: "2px 6px", borderRadius: "4px" }}>{booking.service_booked_date}</span>
              </div>
  
              {/* Booking Mode */}
              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px', alignItems: 'center' }}>
                <strong style={{ color: colors.primary[100], padding: "2px 6px", borderRadius: "4px" }}>Booking Mode:</strong>
                <span style={{ color: booking.booking_mode === 'online' ? '#4CAF50' : '#F44336', fontWeight: 'bold' }}>
                  {booking.booking_mode}
                </span>
              </div>
  
              {/* Booking Price */}
              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px', alignItems: 'center' }}>
                <strong style={{ color: colors.primary[100], padding: "2px 6px", borderRadius: "4px" }}>Booking Price:</strong>
                <span style={{ color: colors.primary[100], padding: "2px 6px", borderRadius: "4px" }}>₹{booking.booking_price}</span>
              </div>
  
              {/* Total Payable Price */}
              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px', alignItems: 'center' }}>
                <strong style={{ color: colors.primary[100], padding: "2px 6px", borderRadius: "4px" }}>Total Payable Price:</strong>
                <span style={{ color: colors.primary[100], padding: "2px 6px", borderRadius: "4px" }}>₹{booking.total_payable_price}</span>
              </div>
            </div>
  
            {/* Package or Service Information */}
            {booking.package_id !== "0" ? (
     <div className="card" style={{
      flex: 1,
      maxWidth: '50%',  // Same here for the service info
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      padding: '20px',
      backgroundColor: '#f9f9f9',
      marginBottom: '30px',
      minHeight: '300px',
    }}>
                
                     <h3 style={{ color: colors.primary[100], padding: "2px 6px", borderRadius: "4px" ,marginBottom: '16px', fontSize:'18px' }}  className="text-center">Package Information</h3>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '12px' }}>
                  <div>
                    <p><strong style={{ color: colors.primary[100], padding: "2px 6px", borderRadius: "4px" }}>Package Name:</strong> {booking.package_name ? booking.package_name : "N/A"}</p>
                    <p><strong style={{ color: colors.primary[100], padding: "2px 6px", borderRadius: "4px" }}>Package Price:</strong> ₹{booking.package_price ? booking.package_price : "0"}</p>
                  </div>
  
                  {/* "View Details" Button */}
                  <button
                    onClick={() => handleEyeClick(booking.itemsData)}
                    style={{ backgroundColor: 'transparent', border: 'none', color: '#007BFF', cursor: 'pointer', fontSize: '16px', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '8px 16px', marginLeft: 'auto', borderRadius: '4px', textTransform: 'uppercase', fontWeight: 'bold' }}
                  >
                    <FiEye style={{ marginRight: '8px' }} /> View Details
                  </button>
                </div>

                {/* Customer Details */}
                <div style={{ marginTop: '20px' }}>
                  <h4 style={{ color: colors.primary[100], padding: "2px 6px", borderRadius: "4px" , marginBottom: '16px' , fontSize:'18px'}}  className = "text-center">Customer Details</h4>
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '12px' }}>
                    <strong style={{ color: colors.primary[100], padding: "2px 6px", borderRadius: "4px" }}>Customer Name:</strong>
                    <span style={{ color: colors.primary[100] }}>{booking.full_name}</span>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
                    <strong style={{ color: colors.primary[100], padding: "2px 6px", borderRadius: "4px" }}>Customer Phone:</strong>
                    <span style={{ color: colors.primary[100] }}>{booking.mobile_number}</span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="card" style={{
                flex: 1,
                minWidth: '48%',
                borderRadius: '8px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                padding: '20px',
                backgroundColor: '#f9f9f9',
                marginBottom: '30px',
                minHeight: '300px',  // Ensure both cards have equal height
              }}>
                <h3 style={{ color: colors.primary[100], padding: "2px 6px", borderRadius: "4px", marginBottom: '16px', fontSize:'18px' }} className="text-center">Service Information</h3>
                
                {booking.itemsData && booking.itemsData.length > 0 ? (
                  booking.itemsData.map((service, index) => (
                    <div key={index}>
                      <p><strong>Service Name:</strong> {service.service_name}</p>
                      <p><strong>Service Price:</strong> ₹{service.service_price}</p>
              
                      {/* "View Details" Button */}
                      <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
                        <button
                          onClick={() => handleEyeClick(service)}
                          style={{ backgroundColor: 'transparent', border: 'none', color: '#007BFF', cursor: 'pointer', fontSize: '16px', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '8px 16px', marginLeft: 'auto', borderRadius: '4px', textTransform: 'uppercase', fontWeight: 'bold' }}
                        >
                          <FiEye/> View Details
                        </button>
                      </div>
                      <div style={{ marginTop: '20px' }}>
                  <h4 style={{ color: colors.primary[100], padding: "2px 6px", borderRadius: "4px" , marginBottom: '16px', fontSize:'18px'}}  className = "text-center">Customer Details</h4>
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
                    <strong style={{ color: colors.primary[100], padding: "2px 6px", borderRadius: "4px" }}>Customer Name:</strong>
                    <span style={{ color: colors.primary[100] }}>{booking.full_name}</span>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
                    <strong style={{ color: colors.primary[100], padding: "2px 6px", borderRadius: "4px" }}>Customer Phone:</strong>
                    <span style={{ color: colors.primary[100] }}>{booking.mobile_number}</span>
                  </div>
                </div>
                    </div>
                  ))
                ) : (
                  <p>No services available.</p>
                )}
              </div>
              
              
            )}

          </div>
          {/* Modal for Service Details */}
          <Modal show={showModal} onClose={closeModal} services={selectedServices} />
        </div>

        <div className="content-area" style={{ marginTop: '80px', padding: '20px', flex: 1, width: '100%' }}>
  <div style={{ display: 'flex', justifyContent: 'space-between', gap: '20px', flexWrap: 'wrap' }}>
 
  <div className="card" style={{
  flex: 1,
  maxWidth: '30%',  // Set maxWidth instead of minWidth to avoid taking over full width
  borderRadius: '8px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  padding: '20px',
  backgroundColor: '#f9f9f9',
  marginBottom: '30px',
  minHeight: '400px',
  marginLeft: '5px',
}}>
  <h3 style={{ color: colors.primary[100], padding: "2px 6px", borderRadius: "4px", fontSize:'18px'}} className="text-center">
    Assigning Details
  </h3>
  <div style={{ marginBottom: '20px' }}>
    <select
      id="serviceProvider"
      style={{
        width: '100%',
        padding: '8px',
        marginTop: '8px',
        backgroundColor: '#fff',
        borderRadius: '4px',
        border: `1px solid ${colors.primary[100]}`,
        fontSize: '16px',
      }}
      value={selectedProviderId || ''}  // Ensures the selected value is correctly set
      onChange={handleProviderSelect}  // Handle the change event when a provider is selected
    >
      <option value="" className="text-center">--Select a Service Provider--</option>
      {serviceProviders.length > 0 ? (
        serviceProviders.map((provider) => (
          <option key={provider.service_provider_id} value={provider.service_provider_id}>
            {provider.full_name}
          </option>
        ))
      ) : (
        <option value="" disabled>No providers available</option>
      )}
    </select>
  </div>
  <div style={{ marginTop: '20px' }}>
    <h4 style={{ color: colors.primary[100], fontSize: '18px', marginBottom: '16px', textAlign: 'center' }}>
      Provider Details
    </h4>
    
    {/* Check if providerDetails exists or if no provider is selected */}
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      {/* Static Field Names with Dynamic or Default N/A Values */}
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <strong>Name:</strong>
        <span>{selectedProviderId && providerDetails?.full_name ? providerDetails.full_name : 'N/A'}</span>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <strong>Email:</strong>
        <span>{selectedProviderId && providerDetails?.email ? providerDetails.email : 'N/A'}</span>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <strong>Phone:</strong>
        <span>{selectedProviderId && providerDetails?.mobile_number ? providerDetails.mobile_number : 'N/A'}</span>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <strong>Vehicle Type:</strong>
        <span>{selectedProviderId && providerDetails?.vehicle_type ? providerDetails.vehicle_type : 'N/A'}</span>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <strong>About Business:</strong>
        <span>{selectedProviderId && providerDetails?.about_business ? providerDetails.about_business : 'N/A'}</span>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <strong>Address:</strong>
        <span>{selectedProviderId && providerDetails?.address ? providerDetails.address : 'N/A'}</span>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <strong>Is Available:</strong>
        <span style={{ color: selectedProviderId && providerDetails?.is_available === '1' ? 'green' : 'red' }}>
          {selectedProviderId && providerDetails?.is_available === '1' ? 'Active' : 'Inactive'}
        </span>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <strong>Is Active:</strong>
        <span style={{ color: selectedProviderId && providerDetails?.is_active === '1' ? 'green' : 'red' }}>
          {selectedProviderId && providerDetails?.is_active === '1' ? 'Available' : 'Not Available'}
        </span>
      </div>
    </div>
  </div>
</div>


<div className="card flex-1 max-w-[70%] rounded-lg shadow-md p-6 bg-gray-100 mb-8 min-h-[400px]">
  <h3 className="text-center text-xl font-semibold text-primary-100 py-1 px-3 rounded-md mb-4">
    Available Provider Information
  </h3>

  {/* Table container with horizontal scroll */}
  <div className="overflow-x-auto">
    <table className="min-w-full table-auto border-collapse">
      <thead>
        <tr className="bg-gray-200 text-center">
          <th className="px-4 py-2 border border-gray-300 font-semibold">Full Name</th>
          <th className="px-4 py-2 border border-gray-300 font-semibold">Mobile Number</th>
          <th className="px-4 py-2 border border-gray-300 font-semibold">Alternate Mobile Number</th>
          <th className="px-4 py-2 border border-gray-300 font-semibold">Business Name</th>
          <th className="px-4 py-2 border border-gray-300 font-semibold">Address</th>
          <th className="px-4 py-2 border border-gray-300 font-semibold">Profile Image</th>
          <th className="px-4 py-2 border border-gray-300 font-semibold">Business Image</th>
          <th className="px-4 py-2 border border-gray-300 font-semibold">About Business</th>
          <th className="px-4 py-2 border border-gray-300 font-semibold">Vehicle Type</th>
          <th className="px-4 py-2 border border-gray-300 font-semibold">Availability</th>
          <th className="px-4 py-2 border border-gray-300 font-semibold">Status</th>
        </tr>
      </thead>
      <tbody>
        {serviceProviders.map((provider, index) => (
          <tr key={index} className="text-center hover:bg-gray-50">
            <td className="px-4 py-2 border border-gray-300">{provider.full_name}</td>
            <td className="px-4 py-2 border border-gray-300">{provider.mobile_number}</td>
            <td className="px-4 py-2 border border-gray-300">{provider.alternate_mobile_number}</td>
            <td className="px-4 py-2 border border-gray-300">{provider.business_name}</td>
            <td className="px-4 py-2 border border-gray-300">{provider.address}</td>
            <td className="px-4 py-2 border border-gray-300">
            <img
          className="w-96 h-20 object-cover mt-2 mx-auto"
          src={`${API_URLS.getproviderImages.url}/${providerDetails.profile_image}`} // Default image if not found
          alt={`${providerDetails.profile_image} Profile`}
        />
            </td>
            <td className="px-4 py-2 border border-gray-300">
            <img
          className="w-96 h-20 object-cover mt-2 mx-auto"
          src={`${API_URLS.getproviderImages.url}/${providerDetails.business_image}`} // Default image if not found
          alt={`${providerDetails.business_name} Business`}
        />
            </td>
            <td className="px-4 py-2 border border-gray-300">{provider.about_business}</td>
            <td className="px-4 py-2 border border-gray-300">{provider.vehicle_type || 'N/A'}</td>
            <td className="px-4 py-2 border border-gray-300">
              {provider.is_available === "1" ? 'Available' : 'Not Available'}
            </td>
            <td className="px-4 py-2 border border-gray-300">
              {provider.is_active === "1" ? 'Active' : 'Inactive'}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
</div>
        
          </div>
        </div>




        </div>
   

    );
};
  
export default AssignBooking;
