import { useState, useEffect } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import Topbar from "./pages/global/Topbar";
import Sidebar from "./pages/global/Sidebar";
import Dashboard from "./pages/dashboard";
import Team from "./pages/team";
import Invoices from "./pages/invoices";
// import Bar from "./pages/bar";
import Form from "./pages/form";
import Line from "./pages/line";
// import Pie from "./pages/pie";
import FAQ from "./pages/faq";
import Geography from "./pages/geography";
import Calendar from "./pages/calendar/calendar";
import Login from "./pages/Login/Login";
import CategoryForm from "./pages/categories/CategoryForm"; 
import Packages from "./pages/Packages"; 
import ServiceForm from "./pages/Services"; 
import ServiceProvider from "./pages/ServiceProvider"; 
import ServiceProviderDetails from "./pages/ServiceProviderDetails"; 
import BookingReport from "./pages/BookingDetails";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import "react-toastify/dist/ReactToastify.css"; // Make sure to import the CSS for the toasts
import { ToastContainer } from 'react-toastify';  // Ensure you have this import
import AssignBooking from './pages/assignBooking';   // Your AssignBooking component

import CustomersPage from './pages/CustomersPage'; 


function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const location = useLocation();
  const navigate = useNavigate(); // For programmatic navigation
 
  useEffect(() => {
    // Retrieve the encoded admin_id from sessionStorage
    const encodedAdminId = sessionStorage.getItem("admin_id");

    // If there's an admin_id stored, decode it
    const adminId = encodedAdminId ? atob(encodedAdminId) : null;

    // If no admin_id exists or it's invalid, redirect to the login page
    if (!adminId && location.pathname !== "/login") {
      navigate("/login");
    }
  }, [location.pathname, navigate]); // Re-run effect if location changes

  const isLoginPage = location.pathname === "/login";

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          {/* Only render Sidebar and Topbar if not on the login page */}
          {!isLoginPage && <Sidebar isSidebar={isSidebar} />}
          <main className="content">
            {!isLoginPage && <Topbar setIsSidebar={setIsSidebar} />}
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/team" element={<Team />} />
              <Route path="/categorypage" element={<CategoryForm />} />
              <Route path="/servicespage" element={<ServiceForm />} />
              <Route path="/packagepage" element={<Packages />} />
              <Route path="/service-providers" element={<ServiceProvider />} />
              <Route path="/service-provider/:service_provider_id" element={<ServiceProviderDetails/>} />
              <Route path="/invoices" element={<Invoices />} />
              <Route path="/form" element={<Form />} />
              {/* <Route path="/bar" element={<Bar />} /> */}
              {/* <Route path="/pie" element={<Pie />} /> */}
              <Route path="/line" element={<Line />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/calendar" element={<Calendar />} />
              <Route path="/geography" element={<Geography />} />
              <Route path="/login" element={<Login />} />
              <Route path="/booking-details" element={<BookingReport />} />
              <Route path="/assign-booking" element={<AssignBooking />} />
              <Route path="/customer-details" element={<CustomersPage />} />
            </Routes>
          </main>
        </div>
   {/* Global ToastContainer */}
   <ToastContainer
          className="!z-[9999]"
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
