import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../theme";
import { TextField, Button, Switch, FormControlLabel, InputAdornment, IconButton } from "@mui/material";
import API_URLS from "../summaryapi/apiUrls";
import axios from "axios";
import { toast } from "react-toastify";
import { Visibility, VisibilityOff } from "@mui/icons-material"; // Eye icons for toggle

// Set the root element for the modal
Modal.setAppElement("#root");

const ServiceProviderModal = ({ open, onClose, onSubmit, provider, fetchServiceProviders }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode); // Get colors based on current theme mode

  const [formData, setFormData] = useState({
    full_name: "",
    mobile_number: "",
    alternate_mobile_number: "",
    business_name: "",
    city: "",
    address: "",
    about_business: "",
    profile_image: null,
    business_image: null,
    is_active: true,
    password: "", // Added password field
    latitude: 0,
    longitude: 0,
  });

  const [imagePreviews, setImagePreviews] = useState({
    profile_image: "",
    business_image: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false); // Manage submission state
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility

  useEffect(() => {
    if (provider) {
      const fetchProviderDetails = async () => {
        try {
          const response = await axios.post(API_URLS.getProviderDetailsById.url, {
            service_provider_id: provider.service_provider_id,
          });
  
          if (response.data.status) {
            const data = response.data.data;
  
            // Convert "is_active" to boolean (true or false)
            const isActive = data.is_active === "1"; // If "1", set true; if "0", set false
  
            // Set form data
            setFormData({
              full_name: data.full_name || "",
              mobile_number: data.mobile_number || "",
              alternate_mobile_number: data.alternate_mobile_number || "",
              business_name: data.business_name || "",
              city: data.city || "",
              address: data.address || "",
              about_business: data.about_business || "",
              profile_image: null,
              business_image: null,
              is_active: isActive,  // Use the boolean value
              password: data.password || "", // Fetch the actual password
              latitude: data.latitude || 0,
              longitude: data.longitude || 0,
            });
  
            // Set image previews
            const profileImageUrl = `${API_URLS.getproviderImages.url}${data.profile_image}`;
            const businessImageUrl = `${API_URLS.getproviderImages.url}${data.business_image}`;
  
            setImagePreviews({
              profile_image: profileImageUrl || "",
              business_image: businessImageUrl || "",
            });
          } else {
            toast.error("Failed to fetch provider details.");
          }
        } catch (error) {
          toast.error("Error fetching provider details.");
        }
      };
  
      fetchProviderDetails();
    } else {
      // Clear form data if no provider is selected (i.e., adding a new provider)
      setFormData({
        full_name: "",
        mobile_number: "",
        alternate_mobile_number: "",
        business_name: "",
        city: "",
        address: "",
        about_business: "",
        profile_image: null,
        business_image: null,
        is_active: true,
        password: "", 
        latitude: 0,
        longitude: 0,
      });
  
      // Clear image previews as well
      setImagePreviews({
        profile_image: "",
        business_image: "",
      });
    }
  }, [provider]);
  
  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle image file changes
  const handleImageChange = (e, imageField) => {
    const { files } = e.target;
    if (files && files[0]) {
      setFormData({ ...formData, [imageField]: files[0] });

      // Preview the selected image
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreviews({
          ...imagePreviews,
          [imageField]: reader.result, // Set the preview image for either profile_image or business_image
        });
      };
      reader.readAsDataURL(files[0]);
    }
  };

  const handleToggleChange = (e) => {
    if (e.target && e.target.checked !== undefined) {
      const isActive = e.target.checked;
      setFormData((prevState) => ({
        ...prevState,
        is_active: isActive,
      }));
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
  
    const formDataToSend = new FormData();
  
    // Include service_provider_id if editing an existing provider
    if (provider) {
      formDataToSend.append("service_provider_id", provider.service_provider_id);
    }
  
    // Append non-image fields
    formDataToSend.append("full_name", formData.full_name);
    formDataToSend.append("mobile_number", formData.mobile_number);
    formDataToSend.append("alternate_mobile_number", formData.alternate_mobile_number);
    formDataToSend.append("business_name", formData.business_name);
    formDataToSend.append("city", formData.city);
    formDataToSend.append("address", formData.address);
    formDataToSend.append("about_business", formData.about_business);
  
    // Append password only if it's different from the masked value
    if (formData.password && formData.password !== "******") {
      formDataToSend.append("password", formData.password);
    }
  
    // Handle Profile Image (only if new image is selected)
    if (formData.profile_image) {
      formDataToSend.append("profile_image", formData.profile_image);
    }
  
    // Handle Business Image (only if new image is selected)
    if (formData.business_image) {
      formDataToSend.append("business_image", formData.business_image);
    }
  
    // Handle is_active
    if (formData.is_active !== undefined) {
      formDataToSend.append("is_active", formData.is_active ? "1" : "0");
    }
  
    try {
      let response;
      if (provider) {
        // Update existing provider
        response = await axios.post(API_URLS.UpdateServiceProvider.url, formDataToSend, {
          headers: { "Content-Type": "multipart/form-data" },
        });
      } else {
        // Add new provider
        response = await axios.post(API_URLS.CreateServiceProvider.url, formDataToSend, {
          headers: { "Content-Type": "multipart/form-data" },
        });
      }
  
      if (response.data.status) {
        toast.success(response.data.message || (provider ? "Service provider updated successfully" : "Service provider added successfully"));
        onSubmit(response.data.data);
        fetchServiceProviders();
        onClose();
      } else {
        toast.error(response.data.message || "Failed to save provider.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Error submitting form.");
    } finally {
      setIsSubmitting(false);
    }
  };
  
  

  return (
    <Modal
      isOpen={open}
      onRequestClose={onClose}
      contentLabel={provider ? "Edit Service Provider" : "Add Service Provider"}
      className="bg-white p-4 rounded shadow-lg w-full sm:w-96 mx-auto"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
    >
      <h2 className="text-2xl mb-4 text-center font-bold" style={{ color: colors.primary[100] }}>
        {provider ? "Edit Service Provider" : "Add Service Provider"}
      </h2>

      <form onSubmit={handleSubmit} className="space-y-2 max-h-[500px] overflow-y-scroll pr-4 mb-4">
        {/* Full Name */}
        <TextField label="Full Name" name="full_name" value={formData.full_name} onChange={handleInputChange} fullWidth required sx={{ backgroundColor: colors.primary[400], color: colors.primary[100] }} />

        {/* Mobile Number */}
        <TextField label="Mobile Number" name="mobile_number" value={formData.mobile_number} onChange={handleInputChange} fullWidth required sx={{ backgroundColor: colors.primary[400], color: colors.primary[100] }} />

        {/* Alternate Mobile Number */}
        <TextField label="Alternate Mobile Number" name="alternate_mobile_number" value={formData.alternate_mobile_number} onChange={handleInputChange} fullWidth sx={{ backgroundColor: colors.primary[400], color: colors.primary[100] }} />

        {/* Business Name */}
        <TextField label="Business Name" name="business_name" value={formData.business_name} onChange={handleInputChange} fullWidth required sx={{ backgroundColor: colors.primary[400], color: colors.primary[100] }} />

        {/* City */}
        <TextField label="City" name="city" value={formData.city} onChange={handleInputChange} fullWidth required sx={{ backgroundColor: colors.primary[400], color: colors.primary[100] }} />

        {/* Address */}
        <TextField label="Address" name="address" value={formData.address} onChange={handleInputChange} fullWidth required sx={{ backgroundColor: colors.primary[400], color: colors.primary[100] }} />

        {/* About Business */}
        <TextField label="About Business" name="about_business" value={formData.about_business} onChange={handleInputChange} fullWidth required sx={{ backgroundColor: colors.primary[400], color: colors.primary[100] }} />

{/* Password */}
<TextField
  label="Password"
  name="password"
  type={showPassword ? "text" : "password"} // Toggle password visibility
  value={formData.password} // Show the actual password in the input field
  onChange={handleInputChange} // Handle change for the password
  fullWidth
  sx={{ backgroundColor: colors.primary[400], color: colors.primary[100] }}
  InputProps={{
    endAdornment: (
      <InputAdornment position="end">
        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
          {showPassword ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      </InputAdornment>
    ),
  }}
/>




        {/* Profile Image Upload */}
        <div>
          <label className="block">Profile Image</label>
          <input type="file" accept="image/*" onChange={(e) => handleImageChange(e, "profile_image")} className="my-2" />
          {imagePreviews.profile_image && (
            <img src={imagePreviews.profile_image} alt="Profile" className="h-16 object-cover rounded" />
          )}
        </div>

        {/* Business Image Upload */}
        <div>
          <label className="block">Business Image</label>
          <input type="file" accept="image/*" onChange={(e) => handleImageChange(e, "business_image")} className="my-2" />
          {imagePreviews.business_image && (
            <img src={imagePreviews.business_image} alt="Business" className="h-16 object-cover rounded" />
          )}
        </div>

        {/* Active Status */}
        <FormControlLabel
          control={<Switch checked={formData.is_active} onChange={handleToggleChange} name="is_active" color="primary" />}
          label="Active"
        />

        {/* Submit Button */}
        <Button type="submit" variant="contained" color="primary" disabled={isSubmitting} fullWidth>
          {isSubmitting ? "Submitting..." : provider ? "Update Provider" : "Add Provider"}
        </Button>
      </form>
    </Modal>
  );
};

export default ServiceProviderModal;
