import React, { useState, useEffect } from "react";
import { FaEdit } from "react-icons/fa";
import { Box, Button, Typography} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../theme";
import axios from "axios";
import PackageModal from "../components/PackageModal";
import PackageServiceModal from "../components/PackageServiceModal";
import API_URLS from "../summaryapi/apiUrls";
import { toast } from 'react-toastify';

const Packages = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [packages, setPackages] = useState([]);
  const [services, setServices] = useState([]);
  // Modal states
  const [openModal, setOpenModal] = useState(false);
  const [newPackage, setNewPackage] = useState({
    package_name: "",
    package_subtitle: "",
    package_description: "",
    package_price: "",
    package_image1: null,
    package_image2: null,
    vehicle_type: "",
    is_active: true, // Default value is true
  });
  
  
  
  useEffect(() => {
    if (openModal) {
      document.body.classList.add("overflow-hidden"); // Lock body scroll
    } else {
      document.body.classList.remove("overflow-hidden"); // Unlock body scroll
    }

    // Clean up on component unmount or modal close
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [openModal]); // Runs every time modalOpen changes

  const [openServiceModal, setOpenServiceModal] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);

  // Fetch packages from API
  const fetchPackages = async () => {
    try {
      const response = await axios.get(API_URLS.getAllAdminPackages.url);
      if (response.data.status) {
        const packages = response.data.data; // Assuming the response data is an array of packages
  
        // Sort packages by created_date in descending order
        const sortedPackages = packages.sort((a, b) => {
          const dateA = new Date(a.created_date);
          const dateB = new Date(b.created_date);
          return dateB - dateA; // Sorting in descending order
        });
  
        setPackages(sortedPackages); // Set the sorted packages
      } else {
        console.error("Failed to fetch packages");
      }
    } catch (error) {
      console.error("Error fetching packages:", error);
    }
  };
  
  useEffect(() => {
    fetchPackages(); 
  }, []);

  const handleServiceSave = async (updatedServices) => {
    try {
      const response = await axios.post(API_URLS.createPackages.url, updatedServices);  // Or use the update endpoint
      if (response.data.status) {
        toast.success("Services updated successfully!");
        setOpenServiceModal(false);  // Close the modal
      } else {
        toast.error(response.data.message || "Error saving services.");
      }
    } catch (error) {
      toast.error("An unexpected error occurred while saving services.");
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewPackage((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };



  const handleImageChange = (e, imageField) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file); // Generate the preview URL
      setNewPackage((prevState) => ({
        ...prevState,
        [imageField]: file, // Save the actual file object
        [`${imageField}_url`]: imageUrl, // Save the image URL for preview
      }));
    }
  };
  
  const handleSave = async () => {
    const formData = new FormData();

    formData.append("package_name", newPackage.package_name);
    formData.append("package_subtitle", newPackage.package_subtitle);
    formData.append("package_description", newPackage.package_description);
    formData.append("package_price", newPackage.package_price);
    formData.append("vehicle_type", newPackage.vehicle_type);
    formData.append("is_active", newPackage.is_active ? "1" : "0");

    // If editing, include the package_id in the form data
    if (newPackage.package_id) {
      formData.append("package_id", newPackage.package_id);
    }

    if (newPackage.package_image1) {
      formData.append("package_image1", newPackage.package_image1);
    }
    if (newPackage.package_image2) {
      formData.append("package_image2", newPackage.package_image2);
    }

    try {
      const response = await fetch(
        newPackage.package_id ? API_URLS.updatePackages.url : API_URLS.createPackages.url,
        {
          method: newPackage.package_id ? API_URLS.updatePackages.method : API_URLS.createPackages.method,
          body: formData,
        }
      );

      const result = await response.json();

      if (result.status) {
        toast.success("Package saved successfully!");
        setOpenModal(false);
        fetchPackages();  // Refresh the list
      } else {
        toast.error(result.message || "Error saving package.");
      }
    } catch (error) {
      toast.error("An unexpected error occurred.");
    }
  };

  const handleEdit = (packageId) => {
    const packageToEdit = packages.find((pkg) => pkg.package_id === packageId);
    setNewPackage({
      ...packageToEdit,
      package_image1_url: packageToEdit.package_image1
        ? `${API_URLS.getPackageImages.url}${packageToEdit.package_image1}`
        : null,
      package_image2_url: packageToEdit.package_image2
        ? `${API_URLS.getPackageImages.url}${packageToEdit.package_image2}`
        : null,
      is_active: packageToEdit.is_active === "1", // Convert string to boolean
    });
    setOpenModal(true);  // Open the modal for editing
  };
  

  const handleAddService = (packageData) => {
    setSelectedPackage(packageData);  // Set the selected package
    setOpenServiceModal(true);  // Open the service modal
  };

  return (
    <Box m="20px" marginTop={4}>
      <Typography
        variant="h4"
        component="h1"
        sx={{ color: colors.primary[100], textAlign: "center", marginBottom: 4 }}
      >
        <h1 className="font-poppins font-bold text-center text-[30px]">Packages</h1>
      </Typography>
      <div className="flex justify-end mb-[16px] w-[95%]">
        <Button
          onClick={() => {
            setNewPackage({
              package_name: "",
              package_subtitle: "",
              package_description: "",
              package_price: "",
              package_image1: null,
              package_image2: null,
              vehicle_type: "",
              is_active: true,
            }); 
            setOpenModal(true); 
          }}
          sx={{
            width: "150px",
            textTransform: "none",
            backgroundColor: colors.greenAccent[500],
            color: "#fff",
            fontWeight: "bold",
            "&:hover": {
              backgroundColor: colors.greenAccent[700],
            },
          }}
        >
          Add Package
        </Button>
      </div>

      <table className="min-w-full table-auto border-collapse">
        <thead className="bg-gray-200">
          <tr
            style={{
              backgroundColor: colors.blueAccent[500],
        color: "#fff",
              fontFamily: '"Source Sans Pro", sans-serif',
              fontWeight: "bold",
            }}
          >
            <th className="px-4 py-2 text-center">Name</th>
            <th className="px-4 py-2 text-center">Vehicle Type</th>
            <th className="px-4 py-2 text-center">Image</th>
            <th className="px-4 py-2 text-center">Image</th>
            <th className="px-4 py-2 text-center">Subtitle</th>
            <th className="px-4 py-2 text-center">Description</th>
            <th className="px-4 py-2 text-center">Price</th>
            <th className="px-4 py-2 text-center">Services</th>
            <th className="px-4 py-2 text-center">Status</th>
            <th className="px-4 py-2 text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          {packages.map((item) => (
            <tr key={item.package_id} className="border-b">
              <td className="px-4 py-2 text-center">{item.package_name}</td>
              <td className="px-4 py-2 text-center">{item.vehicle_type === "2-wheeler" ? "Two-Wheeler" : "Four-Wheeler"}</td>
              <td className="px-4 py-2 text-center">
                {item.package_image1 ? (
                  <img
                    src={`${API_URLS.getPackageImages.url}${item.package_image1}`}
                    alt={item.package_name}
                    className="w-20 h-20 object-cover text-center"
                  />
                ) : (
                  <span>No Image</span>
                )}
              </td>
              <td className="px-4 py-2 text-center">
                {item.package_image2 ? (
                  <img
                    src={`${API_URLS.getPackageImages.url}${item.package_image2}`}
                    alt={item.package_name}
                    className="w-20 h-20 object-cover text-center"
                  />
                ) : (
                  <span>No Image</span>
                )}
              </td>
              <td className="px-4 py-2 text-center">{item.package_subtitle}</td>
              <td className="px-4 py-2 text-center">{item.package_description}</td>
              <td className="px-4 py-2 text-center">₹{item.package_price}</td>
              <td className="px-4 py-2 text-center">
                <button
                  onClick={() => handleAddService(item)}
                  className="text-blue-500 hover:text-blue-700"
                >
                  Add Service
                </button>
              </td>
              <td className="px-4 py-2">
                <span
                  className={`px-2 py-1 text-white rounded-full ${item.is_active == "1" ? "bg-green-500" : "bg-red-500"}`}
                >
                  {item.is_active === "1" ? "Active" : "Inactive"}
                </span>
              </td>
              <td className="px-4 py-2 text-center">
                <button
                  onClick={() => handleEdit(item.package_id)}
                  className="text-yellow-500 hover:text-yellow-700"
                >
                  <FaEdit />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Modal for Add/Edit Package */}
      <PackageModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        onSave={handleSave}
        newPackage={newPackage}
        handleInputChange={handleInputChange}
        handleImageChange={handleImageChange}
      />

      {/* Modal for Adding Services */}
      <PackageServiceModal
  open={openServiceModal}
  onClose={() => setOpenServiceModal(false)}
  packageId={selectedPackage?.package_id}  // Pass the package_id
  packageName={selectedPackage?.package_name}  // Pass the package_name
  packagePrice={selectedPackage?.package_price}
/>

    </Box>
  );
};

export default Packages;
